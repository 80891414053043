import React, { useState } from 'react'
import { ProfileTrackingDTO as TProfileTracking, TrackingDTO, UserProfile } from '../../api'
import { ProfileMutationContent } from '../ProfileManage'
import DayJS from 'dayjs'

import Styles from './ProfileTracking.module.css'
import { useTracking } from '../../../../Hooks/useTracking'

export const ProfileTracking = (data: ProfileMutationContent) => {
    const [showForm, setShowForm] = useState(false)

    return (
        <section>
            <nav style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="outlined" onClick={() => setShowForm(true)}>
                    Agregar registro
                </button>
            </nav>

            {showForm ? (
                <NewTracking {...data} onSubmit={() => setShowForm(false)} />
            ) : (
                <TimeLine {...data} hide={showForm} />
            )}
        </section>
    )
}

const TimeLine = ({ trackings, hide }: UserProfile & { hide: boolean }) => {
    if (hide) return null
    if (!trackings?.length) return <p>No hay eventos registrados...</p>

    const dotClass = (type: typeof trackings[0]['type']) =>
        type === 'enroll' || type === 'new-start-date'
            ? 'success'
            : type.includes('unenroll')
            ? 'error'
            : type === 'promoted'
            ? 'action'
            : type === 'other'
            ? 'info'
            : 'egg'

    const byDate = (a: TrackingDTO, b: TrackingDTO) => +new Date(b.date) - +new Date(a.date)

    return (
        <div className={Styles.listContainer}>
            <ul
                className={`${Styles.customTimeline} timeline`}
                style={{ display: 'block', height: 270 }}
            >
                {trackings.sort(byDate).map(({ type, message, date }) => (
                    <li key={`${date}-${type}-${message}`} className={dotClass(type)}>
                        <span style={{ marginLeft: 20 }}>{message}</span>
                        <small className={Styles.ago}>
                            {`${DayJS(date).fromNow()}, el ${DayJS(date).format('DD/MM HH:mm')}`}.
                        </small>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const NewTracking = ({ _id, onSubmit }: NewTrackingProps) => {
    const { addTracking } = useTracking()
    const [tracking, setTracking] = useState<TProfileTracking>({
        type: 'other',
        message: '',
        date: DayJS().format('YYYY-MM-DDTHH:mm'),
    })

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) =>
        setTracking((old) => ({
            ...old,
            [name]: value,
        }))

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                addTracking({ _id, tracking })
                onSubmit()
            }}
            style={{ maxWidth: 400 }}
        >
            <label>
                Mensaje
                <input type="text" name="message" onChange={handleChange} />
            </label>
            <label>
                <input
                    type="datetime-local"
                    name="date"
                    defaultValue={tracking.date}
                    onChange={handleChange}
                />
            </label>
            <button className="danger" onClick={onSubmit}>
                Cancelar
            </button>
            <button className="success" disabled={!tracking.message}>
                Guardar
            </button>
        </form>
    )
}

type NewTrackingProps = UserProfile & {
    onSubmit: () => void
}
