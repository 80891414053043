import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../../Context/AuthContext'
import styles from './PermissionDenied.module.css'

export const PermissionDenied = () => {
    const { me, tokenIsValid } = useAuthContext()
    const token = localStorage.getItem('token')
    const tokenValid = (token && tokenIsValid(token)) || false

    if (me)
        return (
            <Wrapper>
                <h3>No tiene los permisos necesarios para visitar esta página.</h3>
            </Wrapper>
        )

    if (tokenValid)
        return (
            <Wrapper>
                <p>Aguarde mientras recuperamos su última sesión...</p>
            </Wrapper>
        )

    return (
        <Wrapper>
            <Navigate to="/login" />
        </Wrapper>
    )
}

const Wrapper = ({ children }: Props) => <div className={styles.container}>{children}</div>

interface Props {
    children: ReactNode
}

export default PermissionDenied
