import { Link, useParams } from 'react-router-dom'
import { Section } from '../../../Components/Section'
import { useCohortsNext } from '../../Cohort/Hooks/useCohorts'
import { useMemo } from 'react'
import { Column } from 'react-table'
import { CohortDTOExtended, editCohort } from '../../../infrastructure/api-activation'
import { SliderCheckbox } from '../../../Components/SliderCheckbox'
import DayJS from '../../../Helpers/DayJS'
import { useUIContext } from '../../../Context/UIContext'
import { useQueryClient } from 'react-query'
import { Table } from '../../../Components/Table'
import { EditCohortsLimit } from './CohortsList'

export const CourseCohorts = () => {
    const queryClient = useQueryClient()
    const { slug } = useParams()
    const { showError } = useUIContext()
    const { cohorts, status } = useCohortsNext({ slug })

    const occupation =
        cohorts?.map(({ limit, _count: { profiles } }) =>
            (profiles ? (profiles * 100) / limit : 0).toFixed(2),
        ) ?? []
    const occupationAverage = (
        occupation?.reduce((a, b) => a + Number(b), 0) / occupation?.length
    ).toFixed(2)

    const columns = useMemo(
        () =>
            [
                {
                    id: 'date',
                    Header: 'Fecha de inicio',
                    accessor: ({ date }) => (
                        <span className={DayJS(date).isBefore(DayJS()) ? 'danger' : ''}>
                            {DayJS(date).format('LLLL')}
                        </span>
                    ),
                },
                {
                    id: 'Comienzo',
                    Header: 'Comienzo',
                    accessor: ({ date }) => DayJS(date).fromNow(),
                },
                {
                    id: 'Ocupación',
                    Header: 'Ocupación',
                    accessor: ({ limit, _count: { profiles } }) => {
                        const percentage = (profiles ? (profiles * 100) / limit : 0).toFixed(2)

                        return (
                            <label style={{ display: 'inline' }}>
                                <progress
                                    max={100}
                                    value={percentage}
                                    style={{ marginRight: 20, width: 100 }}
                                    title={`${percentage}% de ocupación`}
                                />
                                {percentage}%
                            </label>
                        )
                    },
                },
                {
                    id: '_count',
                    Header: 'Ocupados',
                    accessor: ({ _count }) => `${_count.profiles}`,
                },
                {
                    id: 'limit',
                    Header: 'Limite',
                    accessor: ({ id, limit, courseId }) => (
                        <EditCohortsLimit id={id} limit={limit} courseId={courseId} />
                    ),
                },
                {
                    id: 'Mostrar',
                    Header: 'Mostrar',
                    accessor: ({ id, courseId, active }) => (
                        <SliderCheckbox
                            checked={active}
                            onChange={() =>
                                editCohort({ id, data: { active: !active } })
                                    .catch(showError)
                                    .finally(() =>
                                        queryClient.invalidateQueries(['Schedule', courseId]),
                                    )
                            }
                        />
                    ),
                },
                {
                    id: 'options',
                    Header: 'Opciones',
                    accessor: ({ id }) => <Link to={`/ecosystem/cohorts/${id}/staff`}>más...</Link>,
                },
            ] as Column<CohortDTOExtended>[],
        [],
    )

    return (
        <Section
            title="Próximas Cohortes"
            options={
                Number(occupationAverage) >= 0 ? (
                    <label style={{ display: 'inline' }}>
                        <progress
                            max={100}
                            value={occupationAverage}
                            style={{ marginRight: 20, width: 100 }}
                            title={`${occupationAverage}% ocupación futura`}
                        />
                        {occupationAverage}% ocupación futura
                    </label>
                ) : null
            }
        >
            {status === 'error' ? (
                <p className="danger">No se pudieron cargar las próximas cohortes</p>
            ) : status === 'loading' ? (
                <p>Cargando las próximas cohortes...</p>
            ) : status === 'success' && !cohorts?.length ? (
                <p className="danger">No hay próximas cohortes para este curso</p>
            ) : status === 'success' && cohorts?.length ? (
                <Table columns={columns} data={cohorts} />
            ) : null}
        </Section>
    )
}
