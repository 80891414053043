import { ProfilesList } from './Components/ProfilesList'
import { useCohort } from './Hooks/useCohorts'
import { useParams } from 'react-router-dom'

export const Cohort = () => {
    const { id = '' } = useParams()
    const { cohort, isLoading } = useCohort(id)

    if (isLoading) return <p>Cargando información de la cohorte, aguarde un momento por favor.</p>

    if (!cohort) return null

    return <ProfilesList {...cohort} />
}
