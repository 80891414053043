import { CSSProperties, PropsWithChildren } from 'react'
import { Title } from './Title'
import Styles from './Section.module.css'

export const Section = ({
    children,
    options,
    style,
    ...titleProps
}: Partial<SectionHeaderProps> & PropsWithChildren) => {
    return (
        <>
            <section className={Styles.section} style={style}>
                {titleProps.title && (
                    <>
                        <Title
                            style={{
                                padding: 0,
                                marginBottom: 0,
                                borderRadius: 'var(--border-radius) var(--border-radius) 0 0',
                            }}
                            size="small"
                            title={titleProps.title}
                            {...titleProps}
                        >
                            {options}
                        </Title>
                        <br />
                    </>
                )}
                {children}
            </section>
        </>
    )
}

type TitleProps = Parameters<typeof Title>[number]
type SectionHeaderProps = {
    title: TitleProps['title']
    subtitle?: TitleProps['subtitle']
    options?: TitleProps['children']
    style?: CSSProperties
}
