import * as XLSX from 'xlsx'

import React, { useState } from 'react'

import { EditableCell } from '../Components/Table'
import { capitalize } from '../Helpers/formatters'

// eslint-disable-next-line
export const useXlsxReader = <T extends {}>(onLoad: (results: T[]) => void) => {
    const [headers, setHeaders] = useState<string[]>([])
    const [file, setFile] = useState<File | null>(null)

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        const files = event.target.files
        if (!files) return

        const file = files[0]
        const xlsxReader = new FileReader()
        xlsxReader.onload = (e) => {
            const result = e.target?.result
            const workbook = XLSX.read(result, { type: 'binary', cellDates: true })
            const workbookName = workbook.SheetNames[0]
            const workSheet = workbook.Sheets[workbookName]
            const results: XlsxResult = XLSX.utils.sheet_to_json(workSheet, {
                header: 1,
                defval: '',
            })
            onLoad(parsedResults(results))
        }
        setFile(file)
        xlsxReader?.readAsBinaryString(file)
    }

    const parsedResults = (data: XlsxResult): T[] => {
        //Only the first letter has to be lowercase for the variables with camelCase
        const headers = data[0]
            .map((item) => item.charAt(0).toLowerCase() + item.slice(1))
            .filter((item) => item !== '')

        setHeaders(headers)

        return data
            .slice(1)
            .filter((row) => row.filter((item) => item !== '').length > 0)
            .map((row, userIndex) =>
                row.reduce((store, value, index) => {
                    const key = headers[index]
                    return {
                        ...store,
                        ...(!!key && { [key]: parseData(value) }),
                        userIndex,
                        error: '',
                    }
                }, {}),
            ) as T[]
    }

    const parseData = (data?: unknown) => {
        if (data instanceof Date)
            return new Intl.DateTimeFormat('es-AR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })
                .format(data)
                .toString()
        if (typeof data === 'string' || typeof data === 'number') return `${data}`
        return ''
    }

    return {
        handleUpload,
        file,
        headers: headers.map((header) => ({
            Header: capitalize(header),
            accessor: header,
            Cell: EditableCell,
        })),
    }
}

type XlsxResult = string[][]
