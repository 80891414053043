import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation, useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'
import { customStyles } from '../../../Helpers/customStyles'
import { Constants } from '../../../Helpers/constants'
import { Mutate } from './ProductDataForm'
import { ProductFromApi, updateProduct } from '../api'

export const ProductState = ({
    id,
    isActive,
    modalState,
}: {
    id: string | undefined
    isActive: boolean | undefined
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}) => {
    const { showToast } = useUIContext()
    const queryClient = useQueryClient()
    const [formChange, setFormChange] = useState<Partial<ProductFromApi>>({ isActive })

    /** Delete product */
    const { mutate } = useMutation(({ id }: Mutate) => updateProduct(id, { isActive: !isActive }), {
        onError: ({ response }) =>
            showToast(response.data.message || Constants.DEFAULT_ERROR_MESSAGE, 'error'),
        onSuccess: () => {
            showToast(Constants.DEFAULT_SUCCESS_MESSAGE, 'success')
            queryClient.invalidateQueries(['fetchProduct'], { exact: false })
            modalState[1](false)
        },
        onSettled: () => setFormChange({}),
    })

    return (
        <Modal isOpen={modalState[0]} style={customStyles}>
            <h2>¿Desea {isActive ? 'inhabilitar' : 'habilitar'} este producto?</h2>
            <button
                type="submit"
                className="success"
                style={{ paddingLeft: '1em' }}
                onClick={() => {
                    {
                        id && mutate({ id, data: formChange })
                    }
                }}
            >
                Confirmar
            </button>
            <button
                className="outlined"
                style={{ paddingLeft: '1em' }}
                type="reset"
                onClick={() => modalState[1](false)}
            >
                Cancelar
            </button>
        </Modal>
    )
}
