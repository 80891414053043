import DayJS from '../../Helpers/DayJS'
import { Icon } from '../../Components/Icon'
import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import { Title } from '../../Components/Title'

import { syncCohort } from './api'
import { useCohort } from './Hooks/useCohorts'
import { useParams } from 'react-router-dom'
import { useUIContext } from '../../Context/UIContext'

export const CohortLayout = ({ children }: Props) => {
    const { showToast } = useUIContext()
    const { id = '' } = useParams()
    const { cohort, isLoading } = useCohort(id)

    if (isLoading) return <p>Cargando información de la cohorte, aguarde un momento por favor.</p>

    if (!cohort) return <p className="danger">Hubo un error inesperado.</p>

    const sync = () => {
        if (!confirm('¿Está realmente seguro que desea sincronizar los datos de esta cohorte?'))
            return
        showToast('Iniciando la sincronización, aguarde por favor...', 'info')
        syncCohort(id)
    }

    const { limit, date, profiles = [] } = cohort

    const occupation = ((profiles.length * 100) / (limit || 1)).toFixed(2)

    return (
        <>
            <Title title={`${DayJS(date).format('LLLL')}`} subtitle={[`Cohorte ID: ${id}`]}>
                <button className="danger" onClick={sync} style={{ display: 'none' }}>
                    Sincronizar
                </button>
                <span style={{ display: 'grid', placeItems: 'center' }}>
                    {profiles.length} usuarios / {occupation}%
                    <progress
                        max={100}
                        value={occupation}
                        style={{ marginRight: 20, width: 200 }}
                        title={`${profiles.length}/${limit} usuarios.`}
                    />
                </span>
                <Link to={`../cohorts/${id}/stats`}>
                    <Icon type="stats" title="Estadísticas" />
                </Link>
                <Link to={`../cohorts/${id}/staff`}>
                    <Icon type="user" title="Usuarios" />
                </Link>
                <Link to={`../cohorts/${id}/bulk`}>
                    <Icon type="bulk" title="Alta masiva" />
                </Link>
            </Title>
            {children}
        </>
    )
}

interface Props {
    section: string
    children: ReactNode
}
