import 'dayjs/locale/es'

import DayJS from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

DayJS.extend(isSameOrBefore)
DayJS.extend(isSameOrAfter)
DayJS.extend(utc)
DayJS.extend(timezone)
DayJS.extend(advancedFormat)
DayJS.extend(localizedFormat)

export default DayJS

export const getAge = (dateBirth: string | Date) => DayJS().diff(DayJS(dateBirth), 'years')
