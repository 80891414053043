import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getOrganizationProfiles } from '../../../infrastructure/api-activation'
import { CourseProfilesList } from '../../../Components/CourseProfilesList'

export const OrganizationProfiles = () => {
    const { slug } = useParams()

    const { data, status } = useQuery({
        queryKey: ['Organization', 'Get', slug, 'Profiles'],
        queryFn: () => getOrganizationProfiles(`${slug}`),
        enabled: !!slug,
    })

    return <CourseProfilesList data={data} status={status} />
}
