import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getCourseProfiles } from '../../../infrastructure/api-activation'
import { CourseProfilesList } from '../../../Components/CourseProfilesList'

export const CourseProfiles = () => {
    const { slug } = useParams()

    const { data, status } = useQuery({
        queryKey: ['Course', 'Get', slug, 'Profiles'],
        queryFn: () => getCourseProfiles(`${slug}`),
        enabled: !!slug,
    })

    return <CourseProfilesList data={data} status={status} />
}
