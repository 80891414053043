import { InputHTMLAttributes, ReactNode } from 'react'
import Styles from './Switch.module.css'

export const Switch = ({
    label,
    checked,
    onClick,
}: InputHTMLAttributes<HTMLInputElement> & { label?: ReactNode }) => {
    return (
        <label className={`${Styles.switch}`}>
            {label}
            <input type="checkbox" onClick={onClick} checked />
            <span className={`${checked ? Styles.on : Styles.off}`}></span>
        </label>
    )
}
