import Axios from 'axios'
import { Stats } from '../Pages/Cohort/api'
import { downloadCSV } from '../Helpers/files'

const { REACT_APP_API_ACTIVATION } = process.env

if (!REACT_APP_API_ACTIVATION) throw 'REACT_APP_API_ACTIVATION not found!'

export const ApiActivation = Axios.create({
    baseURL: `${REACT_APP_API_ACTIVATION}`,
})

export const getLangs = () =>
    ApiActivation.get<API<{ langs: CourseLang[] }>>(`/v1/langs`).then(({ data }) => data.data.langs)

export const getCourses = () =>
    ApiActivation.get<GetCoursesList>(`/admin/networks`).then(({ data }) => data.data.networks)

export const getCourseById = (courseId: string) =>
    ApiActivation.get<GetCourse>(`/admin/networks/${courseId}`).then(({ data }) => data.data)

export const getNextCohortsByCourseSlug = (slug: string) =>
    ApiActivation.get<GetCohortsByCourseSlug>(`/admin/networks/${slug}/cohorts/next`).then(
        ({ data }) => data.data.cohorts,
    )

export const getSubjectsByCourseSlug = (slug: string) =>
    ApiActivation.get<GetSubjectsByCourseSlug>(`/admin/networks/${slug}/subjects`).then(
        ({ data }) => data.data.subjects,
    )

export const createUpdateNetwork = (data: EditCourseDTO | NewCourseDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/networks`,
        data,
    }).then(({ data }) => data.data as CourseDTO)

export const updateNetworkDetails = (courseId: string, data: Partial<NetworkDetailsDTO>) =>
    ApiActivation.patch<GetCoursesDetails>(`/admin/networks/${courseId}/details`, data).then(
        ({ data }) => data.data,
    )

export const addEditFrequency = (data: FrequencyDTO | NewFrequencyDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/frequencies`,
        data,
    }).then(({ data }) => data.data as FrequencyDTO)

export const getSchedule = (id: string) =>
    ApiActivation.get<GetSchedule>(`/admin/schedules/${id}`).then(({ data }) => data.data)

export const addEditSchedule = (data: EditScheduleDTO | NewScheduleDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/schedules`,
        data,
    }).then(({ data }) => data.data as EditScheduleDTO)

export const editCohort = (data: {
    id: string
    data: Partial<Pick<CohortDTO, 'active' | 'limit'>>
}) =>
    ApiActivation.put<EditCohortDTO>(`/admin/cohorts/${data.id}`, data.data).then(
        ({ data }) => data.data,
    )

export const addCohort = (data: NewCohort) =>
    ApiActivation.post<CreateCohort>(`/admin/cohorts`, data).then(({ data }) => data.data)

export const networkStats = ({ id, from, to }: CoursesStatsProps) =>
    ApiActivation.get<Stats>(`/admin/profiles/stats?networkId=${id}&from=${from}&to=${to}`).then(
        ({ data }) => data.data,
    )

export const getAllOrganizations = () =>
    ApiActivation.get<GetAllOrganizations>(`/admin/ecosystem/organizations`).then(
        ({ data }) => data.data.organizations,
    )

export const getOneOrganizations = (id: string) =>
    ApiActivation.get<GetOneOrganizations>(`/admin/ecosystem/organizations/${id}`).then(
        ({ data }) => data.data,
    )

export const addEditOrganization = (id: string, data: Partial<OrganizationDTO>) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: data.id ? `/admin/ecosystem/organizations/${id}` : `/admin/ecosystem/organizations`,
        data,
    }).then(({ data }) => data.data as OrganizationDTO)

export const getAllOrganizationCourses = (slug: string) =>
    ApiActivation.get<GetOrganizationsCourses>(
        `/admin/ecosystem/organizations/${slug}/courses`,
    ).then(({ data }) => data.data.courses)

export const getPublicCourses = () =>
    ApiActivation.get<GetPublicCoursesResponse>(`/admin/courses/public`).then(
        ({ data }) => data.data.courses,
    )

export const getCourseProfiles = (slug: string) =>
    ApiActivation.get<API<{ profiles: CourseProfilesDTO[] }>>(
        `/admin/courses/${slug}/profiles`,
    ).then(({ data }) => data.data.profiles)

export const getOrganizationProfiles = (slug: string) =>
    ApiActivation.get<API<{ profiles: CourseProfilesDTO[] }>>(
        `/admin/ecosystem/organizations/${slug}/profiles`,
    ).then(({ data }) => data.data.profiles)

export const bulkUsers = (users: BulkUserDTO[]) =>
    ApiActivation.post<PostBulkUsers>(`/admin/users/bulk`, { users }).then(({ data }) => data.data)

export const getCountries = () =>
    ApiActivation.get<GetCountries>(`/v1/countries`).then(({ data }) => data.data.countries)

export const assistanceReport = (coursesIds: string[]) =>
    ApiActivation.post<string>(`/admin/courses/reports/assistance`, { coursesIds }).then(
        ({ data }) => downloadCSV([data], 'assistance-report.csv'),
    )

export const updateUser = (mongoId: string, data: Partial<PatchUserDataDTO>) =>
    ApiActivation.patch<API<Partial<PatchUserDataDTO>>>(`/admin/users/${mongoId}`, data).then(
        ({ data }) => data,
    )

// Interfaces
type GetPublicCoursesResponse = API<{ courses: PublicCoursesDTO[] }>
type GetOrganizationsCourses = API<{ courses: OrganizationCourseDTO[] }>
type GetCoursesList = API<{ networks: CourseExtendedDTO[] }>
type GetCoursesDetails = API<NetworkDetailsDTO>
type GetSchedule = API<ScheduleWithQuotasDTO>
type GetCourse = API<CourseExtendedDTO & { details: CourseDetailsDTO }>
type GetCohortsByCourseSlug = API<{ cohorts: CohortDTOExtended[] }>
type GetSubjectsByCourseSlug = API<{ subjects: CourseSubjectDTO[] }>
type GetCountries = API<{ countries: CountryDTO[] }>
type EditCohortDTO = API<CohortDTO>
type CreateCohort = API<CohortDTO>
type GetAllOrganizations = API<{ organizations: OrganizationDTO[] }>
type GetOneOrganizations = API<OrganizationDTO>
type PostBulkUsers = API<{
    users: (BulkUserDTO & {
        success: boolean
        status: string
    })[]
}>

type PatchUserDataDTO = {
    password?: string
    email?: string
}

type DateArg = ConstructorParameters<typeof Date>[0]

export type PublicCoursesDTO = {
    id: string
    title: string
    slug: string
    lang: string
    contentType: string
    visible: boolean
    public: boolean
    enabled: boolean
    createdAt: string
    updatedAt: string
    organizationId: string
    detailId: string
    organizations: {
        id: string
        name: string
        slug: string
        description: string
        logo: string
        referral: string
        domain: string
        marketplace: string
        apiKey: string
        apiSecret: string
        isActive: boolean
        enabled: boolean
        createdAt: string
        updatedAt: string
    }
}

export type OrganizationCourseDTO = {
    id: string
    title: string
    slug?: string
    contentType: string
    enabled: boolean
    visible: boolean
    public: boolean
    lang: string
    environment?: string
    path?: string
    level?: string
    createdAt: string
}

export type CourseDTO = {
    id?: string
    title: string
    slug?: string
    contentType: string
    enabled: boolean
    visible: boolean
    public: boolean
    lang: string
    organizationId: string | null
    createdAt: Date
    updatedAt: Date
}

export interface CohortDTOExtended extends CohortDTO {
    _count: {
        profiles: number
    }
}

export type NewCourseDTO = CourseDTO

export type EditCourseDTO = Partial<CohortDTO> & {
    id: string
}

export type CourseExtendedDTO = CourseDTO & {
    detailId: string
    frequencies: FrequencyDTO[]
    _count?: {
        courses: number
        frequencies: number
    }
}

export interface CourseSubjectDTO {
    id: string
    description: string
    number_order: number
    level_id: string
    network_id: string
    environment: {
        id: string
        name: string
        color?: any
    }
    path: {
        id: string
        name: string
        color?: any
    }
    level: {
        id: string
        name: string
        color?: any
    }
}

type CoursesStatsProps = {
    id: string
    from: DateArg
    to: DateArg
}

interface CourseLang {
    code: string
    name: string
    flag: string
}

export type CourseDetailsDTO = {
    id: string
    steps?: number
    status?: string
    short_description?: string
    long_description?: any
    price_usd?: string
    price_usd_discount?: string
    contentful_id?: any
}

export type NewFrequencyDTO = Omit<FrequencyDTO, 'id' | 'courses'>
export interface EditFrequencyDTO {
    id: string
    networkId: string
    title: string
    description: string
    sunday: boolean
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
}
export interface FrequencyDTO extends EditFrequencyDTO {
    courses: {
        networkId: string
        frequencyId: string
        id: string
        mongoId: string
        institution: string
        from: number
        to: number
        fromMinutes: number
        toMinutes: number
        createdAt: string
        updatedAt: string
    }[]
}

export interface EditScheduleDTO extends NewScheduleDTO {
    id: string
}
export interface NewScheduleDTO {
    networkId: string
    frequencyId: string
    mongoId: string
    from: number
    to: number
    fromMinutes: number
    toMinutes: number
    institution: string
}
export interface ScheduleWithQuotasDTO extends NewScheduleDTO {
    id: string
    createdAt: Date
    updatedAt: Date
    quotas: {
        courseId: string
        id: string
        taken: number
        limit: number
        active: boolean
        date: Date
        createdAt: Date
        updatedAt: Date
        _count: {
            profiles: number
        }
    }[]
    active: boolean
}

export interface CohortDTO {
    courseId: string
    id: string
    taken: number
    limit: number
    active: boolean
    date: string
    createdAt: Date
    updatedAt: Date
}
export type NewCohort = Pick<CohortDTO, 'date' | 'active' | 'taken' | 'limit' | 'courseId'>

export type NetworkDetailsDTO = {
    short_description: string
    long_description: string
    price_usd: number
    status: string
    contentful_id: string
}

type API<T> = { data: T; message: string }

export type OrganizationDTO = {
    id: string
    name: string
    slug: string
    description?: string
    logo?: string
    referral: string
    domain: string
    isActive: boolean
    enabled: boolean
    marketplace: 'open' | 'closed' | 'none'
    apiKey?: string
    apiSecret?: string
    notifyUsersByEmail?: boolean
    createdAt: string
    updatedAt: string
}

export type CourseProfilesDTO = {
    status: CourseStatus
    courseTitle: string | null
    courseSlug: string | null
    courseId: string | null
    profileId: string
    paidId?: any
    userId: string
    email: string
    name: string
    lastname: string
    urlImage?: any
    isExpertMentor: boolean
    progressiveSchedule?: any
    classId: string
    createdAt: string
    active: boolean
    setToUnactiveDate?: any
    quitReason?: any
    role: string
}

export interface BulkUserDTO {
    email: string
    name: string
    lastname: string
    phone: string
    country_iso3: string
    referrer?: string
}

export interface CountryDTO {
    id: string
    name: string
    iso2: string
    iso3: string
}

export type CourseStatus =
    | 'unknown' // Without progressiveSchedule
    | 'enrolled' // With progressiveSchedule.start > today
    | 'studying' // With progressiveSchedule.start <= today & without progressiveSchedule.end
    | 'finished' // With progressiveSchedule.end > today
    | 'deserted' // With active = false
