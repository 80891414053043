import React, { useState } from 'react'
import { NewProductDataForm } from './NewProductDataForm'
import Modal from 'react-modal'
import { Icon } from '../../../Components/Icon'
import { useMutation } from 'react-query'
import { ProductFromApi, createProduct } from '../api'
import { useUIContext } from '../../../Context/UIContext'
import { Alert } from '../../../Components/Alert'
import { Constants } from '../../../Helpers/constants'

const AlertForm = ({ isOpen, setIsOpen }: Props) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            width: '30em',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFF',
            borderRadius: '3%',
        },
        overlay: {
            backgroundColor: 'rgb(135 135 135 / 75%)',
        },
    }

    const [formChanges, setFormChanges] = useState<Partial<ProductFromApi>>({})
    const { showToast } = useUIContext()

    /** Create product */
    const { mutate } = useMutation(({ data }: Mutate) => createProduct(data), {
        onMutate: () => Alert,
        onError: ({ response }) =>
            showToast(response.data.message || Constants.DEFAULT_ERROR_MESSAGE, 'error'),
        onSuccess: () => setIsOpen(false),
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, type } = event.target
        let { value } = event.target

        // Parsing dates from input to valid API date string
        if (type === 'date') value = new Date(value).toISOString()

        setFormChanges((prev) => ({ ...prev, [name]: value }))
    }

    return (
        <Modal isOpen={isOpen} style={customStyles}>
            <div className="flex">
                <Icon
                    type="tag"
                    style={{
                        color: 'black',
                        display: 'flex',
                        margin: '0 auto',
                        height: '1em',
                    }}
                    size="large"
                    onClick={() => {}}
                />
                <Icon
                    type="close"
                    style={{
                        color: 'black',
                        display: 'flex',
                        //margin: '0 auto',
                    }}
                    onClick={() => setIsOpen(false)}
                />
            </div>
            <div
                className="column-span"
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
                <h3>Nuevo producto</h3>
            </div>
            <div className="flex" style={{ margin: '5%' }}>
                <NewProductDataForm handleChange={handleChange} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <button
                    className="egg"
                    style={{ width: '7em', marginTop: '0.5em' }}
                    onClick={() => mutate({ data: formChanges })}
                    disabled={!Object.keys(formChanges).length}
                >
                    Añadir
                </button>
            </div>
        </Modal>
    )
}

export const NewProduct = ({ isOpen, setIsOpen }: Props) => {
    return (
        <>
            <AlertForm isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    )
}

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface Mutate {
    data: Partial<ProductFromApi>
}
