import { useQuery } from 'react-query'
import { getLangs as queryFn } from '../../../infrastructure/api-activation'

export const useLags = () => {
    const queryKey = ['Langues']

    const { data: langs, ...rest } = useQuery({ queryFn, queryKey })

    return { langs, ...rest }
}
