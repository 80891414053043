import React from 'react'

export const Tag = ({ variant, label, ...rest }: TagProps) => {
    return (
        <span className={`tags ${variant}`} {...rest}>
            {label}
        </span>
    )
}

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
    variant: 'success' | 'info' | 'error' | 'action' | 'egg' | 'primary' | 'secondary'
    label: string
}
