import { App, ENV } from '../AppConfig'
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { signOut as firebaseSignOut, signInWithEmailAndPassword } from 'firebase/auth'

import Axios from 'axios'
// Interfaces
import { UsersRoles } from '../Interfaces/users'
import { capitalize } from '../Helpers/formatters'
import { firebaseAuth } from '../Services/Firebase'
import { handleMessageError } from '../Helpers/error'
import jwt_decode from 'jwt-decode'
// Hooks
import { useUIContext } from './UIContext'
import { ApiActivation } from '../infrastructure/api-activation'
import { ApiWidget } from '../infrastructure/api-widget'
import { ApiEgg } from '../infrastructure/api-egg'
import { ApiOnline } from '../infrastructure/api-online'
import { ApiSocket } from '../infrastructure/api-socket'
import { ApiHCA } from '../infrastructure/api-hca'

// Create Context
const Context = createContext<AuthContextValues>({} as AuthContextValues)
Context.displayName = 'AuthContext'

// Create Hook
export const useAuthContext = () => useContext(Context)

// Create HOC
export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const { showToast } = useUIContext()
    const [me, setMe] = useState<AuthContextValues['me']>(null)

    /** SignIn with email + password or token */
    const signIn: AuthContextValues['signIn'] = async ({ email, password, token }) => {
        try {
            // SignIn with email & password
            if (!token) {
                if (!email || !password) {
                    Promise.reject('Revise el usuario y contraseña')
                    return null
                }
                const auth = firebaseAuth()
                const { user } = await signInWithEmailAndPassword(auth, email, password)
                const firebaseToken = await user.getIdToken(true)
                token = await getAPIToken(firebaseToken)
            }

            // Get me data
            if (!token) {
                return Promise.reject('No token found')
            }

            const me = await getMeData(token)

            setMe(me)
            return me
        } catch (error) {
            return Promise.reject('Revise el usuario y contraseña.')
        }
    }

    /** SignOut from firebase & clean all data session */
    const signOut: AuthContextValues['signOut'] = () =>
        firebaseSignOut(firebaseAuth())
            .then(cleanSessionData)
            .catch(() => showToast('No es posible cerrar la sesión', 'error'))

    /** Clean all session data */
    const cleanSessionData = () => {
        localStorage.removeItem('token')
        ApiEgg.defaults.headers.common.Authorization = false
        ApiActivation.defaults.headers.common.Authorization = false
        ApiHCA.defaults.headers.common.Authorization = false
        ApiWidget.defaults.headers.common.Authorization = false
        ApiOnline.defaults.headers.common.Authorization = false
        ApiSocket.defaults.headers.common.Authorization = false
        Axios.defaults.headers.common.Authorization = false
        setMe(null)
    }

    /** Get token used by API and set baseURL */
    const getAPIToken = async (firebaseToken: string) => {
        try {
            Axios.defaults.baseURL = App.API
            const { data } = await Axios.post<GetAPITokenResponse>('/user/verifyToken', {
                idToken: firebaseToken,
            })
            const { token } = data.data

            setNewAccessToken(token)

            return token
        } catch (error) {
            Promise.reject(error)
            return undefined
        }
    }

    /** Get login user data */
    const getMeData = async (token: string) => {
        try {
            if (token === 'testing')
                return { test: 'PASSED' } as unknown as GetMeResponse['data']['user']
            Axios.defaults.baseURL = App.API

            setNewAccessToken(token)

            await Axios.get<GetMeResponse>('/me')

            setNewAccessToken(token)

            const {
                data: { data },
            } = await Axios.get<GetMeResponse>('/me')
            const { user, roles } = data

            // Admin role as a highest priority, otherwise the first role
            const role = roles.find((role) => role === 'admin') ?? roles[0]

            user.role = role
            user.displayName = `${capitalize(user.name)} ${capitalize(user.lastname)}`

            return user
        } catch (error) {
            localStorage.removeItem('token')
            showToast(handleMessageError(error), 'error')
            return null
        }
    }
    /** Returns true if token is valid */
    const tokenIsValid = (token: string): boolean => {
        const { exp } = jwt_decode<{ exp: number }>(token)
        const isValid = exp > Date.now() / 1000
        if (!isValid) cleanSessionData()
        return isValid
    }

    const setNewAccessToken = (token: string | null) => {
        if (!token) return cleanSessionData()

        if (`Bearer ${token}` === ApiActivation.defaults.headers.common.Authorization) return

        if (ENV !== 'production')
            // eslint-disable-next-line no-console
            console.info(`Access token refreshed!`, `https://jwt.io/#debugger-io?token=${token}`)

        ApiEgg.defaults.headers.common['access-token'] = `${token}`
        ApiActivation.defaults.headers.common.Authorization = `Bearer ${token}`
        ApiHCA.defaults.headers.common.Authorization = `Bearer ${token}`
        ApiSocket.defaults.headers.common.Authorization = `Bearer ${token}`
        ApiWidget.defaults.headers.common.Authorization = `${token}`
        ApiOnline.defaults.headers.common['access-token'] = `${token}`
        Axios.defaults.headers.common['access-token'] = `${token}`

        localStorage.setItem('token', token)
    }

    return (
        <Context.Provider value={{ me, role: me?.role || null, signIn, signOut, tokenIsValid }}>
            {children}
        </Context.Provider>
    )
}

// Interfaces
interface AuthContextValues {
    me: GetMeResponse['data']['user'] | null
    role: UsersRoles | null
    // eslint-disable-next-line no-unused-vars
    signIn: ({ email, password }: SignInProps) => Promise<GetMeResponse['data']['user'] | null>
    signOut: () => void
    // eslint-disable-next-line no-unused-vars
    tokenIsValid: (token: string) => boolean
}

interface SignInProps {
    email?: string
    password?: string
    token?: string
}

interface GetAPITokenResponse {
    data: {
        _id: string
        _profileId: string
        role: string
        token: string
        name: string
        lastname: string
        email: string
        phone: string
        urlImage: string
        statusMessage: string
        language: string
        activeProfile: {
            _id: string
            role: string
        }
    }
    message: string
}

interface GetMeResponse {
    data: {
        user: {
            address: {
                street: string
                numeration: string
                floor: string
                apartment?: string
                country: string
                city: string
            }
            active: boolean
            language: string
            isVerified: boolean
            loginCount: number
            _id: string
            uid: string
            email: string
            provider: string
            name: string
            lastname: string
            createdAt: string
            birthdate: string
            dni: string
            genre: string
            phone: string
            urlImage: string
            lastLogin: string
            tutorialWidgetCompleted: boolean
            isFirstLogin: boolean
            role: UsersRoles
            displayName: string
        }
        profiles: {
            totalVotes: number
            _id: string
            votes: number
            timesFacilitator: number
            role: string
            _userId: string
            active: boolean
            email: string
            lastname: string
            name: string
            zoom: string[]
            lastVotes: string[]
        }[]
        activeProfile: {
            totalVotes: number
            _id: string
            votes: number
            timesFacilitator: number
            role: string
            _userId: string
            active: boolean
            email: string
            lastname: string
            name: string
            zoom: string[]
            lastVotes: string[]
        }
        roles: UsersRoles[]
        notifications: {
            count: number
        }
    }
    message: string
}
