import { Link } from 'react-router-dom'
import { Section } from '../../../Components/Section'
import { Table } from '../../../Components/Table'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getPublicCourses as queryFn } from '../../../infrastructure/api-activation'
import { Column } from 'react-table'
import DayJS from '../../../Helpers/DayJS'
import { EcosystemTitle } from './EcosystemTitle'

export const PublicCoursesList = () => {
    const { data, status } = useQuery({
        queryKey: ['Organization', 'Get', 'Public', 'Courses'],
        queryFn,
    })

    const columns = useMemo(
        () =>
            [
                {
                    id: 'title',
                    Header: 'Curso',
                    accessor: ({ title, slug }) => <Link to={`../courses/${slug}`}>{title}</Link>,
                },
                {
                    Header: 'Slug',
                    accessor: 'slug',
                },
                {
                    id: 'organization',
                    Header: 'Organización',
                    accessor: ({ organizations }) =>
                        organizations ? (
                            <Link to={`../${organizations?.slug ?? organizations?.id}`}>
                                {organizations?.name}
                            </Link>
                        ) : (
                            '-'
                        ),
                },
                {
                    Header: 'Antigüedad',
                    accessor: ({ createdAt }) => (createdAt ? DayJS(createdAt).fromNow(true) : '-'),
                },
                {
                    id: 'listed',
                    Header: 'Listado',
                    accessor: ({ visible }) => (visible ? 'Si' : 'No'),
                },
            ] as Column<NonNullable<typeof data>[number]>[],
        [],
    )

    return (
        <>
            <EcosystemTitle />
            <Section title="Cursos Públicos">
                {status === 'error' ? (
                    <p className="danger">No se pudieron cargar los cursos</p>
                ) : status === 'loading' ? (
                    <p>Cargando cursos...</p>
                ) : status === 'success' && !data?.length ? (
                    <p className="danger">No hay cursos públicos</p>
                ) : status === 'success' && data?.length ? (
                    <Table columns={columns} data={data} canDownloadCsv={false} />
                ) : null}
            </Section>
        </>
    )
}
