import React from 'react'
import { useUIContext } from '../Context/UIContext'
import { copyToClipboard } from '../Helpers/utilities'
import { Icon } from './Icon'

export const TextToClipboard = ({ text }: { text: string }) => {
    const { showToast } = useUIContext()
    return (
        <span
            onClick={() => copyToClipboard(text).then(() => showToast('Copiado al portapapeles'))}
            style={{ cursor: 'pointer' }}
            title="Haga click para copiar al portapapeles"
            aria-hidden
        >
            {`${text} `}
            <Icon type="clipboard" size="small" />
        </span>
    )
}
