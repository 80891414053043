import {
    CourseDTO,
    EditCourseDTO,
    NewCourseDTO,
    createUpdateNetwork,
} from '../../../infrastructure/api-activation'
import { FormEvent, useState } from 'react'
import { Modal, ModalProps } from '../../../Components/Modal'
import { Title } from '../../../Components/Title'
import { useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'
import { useLags } from '../Hooks/useLags'
import { toSlug } from '../../../Helpers/formatters'
import { Section } from '../../../Components/Section'
import { Switch } from '../../../Components/Switch'
import { useOrganizations } from '../Hooks/useOrganizations'
import { useParams } from 'react-router-dom'

export const initialData = {
    title: 'Título',
    contentType: 'Tipo de contenido',
    slug: 'titulo_del_curso',
    visible: true,
    enabled: true,
    lang: 'en',
} as NewCourseDTO

export const AddEditCourse = ({
    course,
    onClose,
    ...modalProps
}: ModalProps & { course: Partial<CourseDTO> }) => {
    const { slug = '' } = useParams()
    const queryClient = useQueryClient()
    const { organizations } = useOrganizations()
    const { langs } = useLags()
    const { showToast, showError } = useUIContext()
    const [data, setData] = useState(course)
    const isEditing = 'id' in course

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()

        createUpdateNetwork(data as EditCourseDTO)
            .then(() => {
                showToast('Curso guardado correctamente')
            })
            .catch(showError)
            .finally(() => {
                queryClient.invalidateQueries(['Course', 'Get', slug])
                queryClient.invalidateQueries(['Organization', 'Get'], { exact: false })
                onClose()
            })
    }

    return (
        <Modal {...modalProps} onClose={onClose} onCancel={onClose} onConfirm={() => onSubmit()}>
            <Title
                title={isEditing ? course.title : 'Agregar Curso'}
                subtitle={data.id ? `Course ID: ${data.id}` : undefined}
                size="small"
                style={{ minWidth: 800 }}
            >
                <Switch
                    label="Habilitado"
                    checked={data.enabled}
                    onClick={() =>
                        setData((prev) => ({
                            ...prev,
                            enabled: !prev.enabled,
                        }))
                    }
                />

                <Switch
                    label="Listado"
                    checked={data.visible}
                    onClick={() => setData((prev) => ({ ...prev, visible: !prev.visible }))}
                />

                <Switch
                    label="Público"
                    checked={data.public}
                    onClick={() => setData((prev) => ({ ...prev, public: !prev.public }))}
                />
            </Title>
            <Section>
                <form onSubmit={onSubmit}>
                    <div className="columns">
                        <label>
                            Título
                            <input
                                id="title"
                                type="text"
                                name="title"
                                required={true}
                                value={data?.title}
                                onChange={({ target: { value, name } }) =>
                                    setData((prev) => ({ ...prev, [name]: value }))
                                }
                            />
                        </label>

                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
                        <label htmlFor="slug">
                            <div className="flex" style={{ justifyContent: 'space-between' }}>
                                <span>Slug</span>
                                <button
                                    type="button"
                                    className="outlined"
                                    onClick={() =>
                                        setData((prev) => ({ ...prev, slug: toSlug(prev.title) }))
                                    }
                                >
                                    sugerir
                                </button>
                            </div>
                            <input
                                id="slug"
                                name="slug"
                                type="text"
                                required={true}
                                value={data.slug}
                                onChange={({ target: { value, name } }) =>
                                    setData((prev) => ({ ...prev, [name]: value }))
                                }
                            />
                        </label>
                    </div>

                    <div className="columns">
                        <label>
                            Organización a la que pertenece
                            <select
                                id="organizationId"
                                name="organizationId"
                                value={data.organizationId ?? ''}
                                onChange={({ target: { name, value } }) =>
                                    setData((prev) => ({ ...prev, [name]: value ? value : null }))
                                }
                            >
                                <option value="">Ninguna</option>
                                {organizations?.map(({ id, name }) => (
                                    <option value={id} key={id}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <div className="columns">
                        <label>
                            Idioma
                            <select
                                name="lang"
                                id="lang"
                                value={data.lang}
                                onChange={({ target: { value, name } }) =>
                                    setData((prev) => ({ ...prev, [name]: value }))
                                }
                            >
                                {langs?.map(({ code, name, flag }) => (
                                    <option value={code} key={code}>
                                        {flag} {name}
                                    </option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Tipo de Contenido
                            <input
                                id="contentType"
                                type="text"
                                name="contentType"
                                value={data.contentType}
                                onChange={({ target: { value, name } }) =>
                                    setData((prev) => ({ ...prev, [name]: value }))
                                }
                            />
                        </label>
                    </div>
                </form>
            </Section>
        </Modal>
    )
}
