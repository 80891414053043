import DayJs from 'dayjs'
import utc from 'dayjs/plugin/utc'

DayJs.extend(utc)

export const parseDateFromApiToView = (date: string) =>
    date.split('T')[0].split('-').reverse().join('/')

export const parseDateFromViewToApi = (date: string) =>
    date.split('/').reverse().join('-').concat('T03:00:00.000Z')

export const parseQuotaDate = (date: string) => DayJs(date).toISOString()

const parseHour = (hour: number) => DayJs.utc().hour(hour).format('HH')

export const generateTimeRange = (from: number, to: number) =>
    `De ${parseHour(from)}hs a ${parseHour(to)}hs`

export const rtf = new Intl.RelativeTimeFormat(undefined, {
    localeMatcher: 'best fit',
    numeric: 'auto',
    style: 'long',
})

export const hoursToLocal = (hour: number) =>
    DayJs().utc().hour(hour).minute(0).local().format('HH:mm')

export const fromUTCToLocalRange = (
    from: number,
    to: number,
    fromMinutes: number,
    toMinutes: number,
) => `${parseHourWidthMinutes(from, fromMinutes)} a ${parseHourWidthMinutes(to, toMinutes)}`

export const parseHourWidthMinutes = (hour: number, minutes: number) =>
    DayJs().utc().hour(hour).minute(minutes).local().format('HH:mm')

export const generateTime = (from: number, to: number, fromMinutes: number, toMinutes: number) => ({
    start: parseHourWidthMinutes(from, fromMinutes),
    end: parseHourWidthMinutes(to, toMinutes),
})
