import { AddEditSchedule } from './AddEditSchedule'
import { CohortsList } from './CohortsList'
import { Title } from '../../../Components/Title'

import { useCourse } from '../Hooks/useCourse'
import { useParams } from 'react-router-dom'
import { useSchedule } from '../Hooks/useSchedule'
import { useState } from 'react'
import { fromUTCToLocalRange } from '../../../Helpers/dateFormatters'

export const Schedule = () => {
    const { id = '' } = useParams()
    const { schedule, isLoading, isError } = useSchedule({ scheduleId: id })
    const [isEditing, setIsEditing] = useState(false)
    const { course } = useCourse(schedule?.networkId)

    if (isLoading || !course) return <p>Cargando información del horario...</p>
    if (isError) return <p className="danger">Error al cargar la información del horario...</p>
    if (!schedule) return <p className="danger">El horario no existe.</p>

    const { from, to, fromMinutes, toMinutes } = schedule

    return (
        <>
            <Title
                title={`${course.title} de ${fromUTCToLocalRange(
                    from,
                    to,
                    fromMinutes,
                    toMinutes,
                )}`}
                subtitle={[`Horario ID: ${id}`, `Mongo ID: ${schedule.mongoId}`]}
            >
                <button className="outlined" onClick={() => setIsEditing(true)}>
                    Editar Horario
                </button>
            </Title>

            <AddEditSchedule
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                schedule={schedule}
            />

            <CohortsList {...schedule} scheduleId={id} />
        </>
    )
}
