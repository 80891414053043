/**
 * Sources:
 *  - Main: https://heroicons.com/
 *  - Alternative: https://github.com/PKief/vscode-material-icon-theme/tree/main/icons
 */

/** Please, keep the icons in alphabetical order */
export const icons = {
    ar: (
        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ar" viewBox="0 0 640 480">
            <path fill="#74acdf" d="M0 0h640v480H0z" />
            <path fill="#fff" d="M0 160h640v160H0z" />
            <g id="c" transform="translate(-64) scale(.96)">
                <path
                    id="a"
                    fill="#f6b40e"
                    stroke="#85340a"
                    strokeWidth="1.1"
                    d="m396.8 251.3 28.5 62s.5 1.2 1.3.9c.8-.4.3-1.5.3-1.5l-23.7-64m-.7 24.1c-.4 9.4 5.4 14.6 4.7 23-.8 8.5 3.8 13.2 5 16.5 1 3.3-1.3 5.2-.3 5.7s3-2.1 2.4-6.8c-.7-4.6-4.2-6-3.4-16.3.8-10.3-4.2-12.7-3-22"
                />
                <use width="100%" height="100%" transform="rotate(22.5 400 250)" />
                <use width="100%" height="100%" transform="rotate(45 400 250)" />
                <use width="100%" height="100%" transform="rotate(67.5 400 250)" />
                <path
                    id="b"
                    fill="#85340a"
                    d="M404.3 274.4c.5 9 5.6 13 4.6 21.3 2.2-6.5-3.1-11.6-2.8-21.2m-7.7-23.8 19.5 42.6-16.3-43.9"
                />
                <use width="100%" height="100%" transform="rotate(22.5 400 250)" />
                <use width="100%" height="100%" transform="rotate(45 400 250)" />
                <use width="100%" height="100%" transform="rotate(67.5 400 250)" />
            </g>
            <use width="100%" height="100%" transform="rotate(90 320 240)" />
            <use width="100%" height="100%" transform="rotate(180 320 240)" />
            <use width="100%" height="100%" transform="rotate(-90 320 240)" />
            <circle cx="320" cy="240" r="26.7" fill="#f6b40e" stroke="#85340a" strokeWidth="1.4" />
            <path
                id="h"
                fill="#843511"
                d="M329.1 234.3c-1.8 0-3.6.8-4.6 2.4 2 1.9 6.6 2 9.7-.2a7 7 0 0 0-5.1-2.2zm0 .4c1.7 0 3.4.8 3.6 1.6-2 2.3-5.3 2-7.4.4a4.3 4.3 0 0 1 3.8-2z"
            />
            <use width="100%" height="100%" transform="matrix(-1 0 0 1 640.2 0)" />
            <use width="100%" height="100%" transform="matrix(-1 0 0 1 640.2 0)" />
            <use width="100%" height="100%" transform="translate(18.1)" />
            <use width="100%" height="100%" transform="matrix(-1 0 0 1 640.2 0)" />
            <path
                fill="#85340a"
                d="M316 243.7a1.9 1.9 0 1 0 1.8 2.9 4 4 0 0 0 2.2.6h.2a3.9 3.9 0 0 0 2.3-.6 1.9 1.9 0 1 0 1.8-3c.5.3.8.7.8 1.3 0 .6-.5 1.2-1.2 1.2a1.2 1.2 0 0 1-1.2-1.2 3 3 0 0 1-2.6 1.7 3 3 0 0 1-2.5-1.7 1.2 1.2 0 0 1-1.3 1.2c-.6 0-1.2-.6-1.2-1.2s.3-1 .8-1.2zm2 5.5c-2.1 0-3 1.8-4.8 3 1-.4 1.9-1.2 3.3-2s2.7.2 3.5.2c.8 0 2-1 3.5-.2 1.4.8 2.3 1.6 3.3 2-1.9-1.2-2.7-3-4.8-3a5.5 5.5 0 0 0-2 .6 5.5 5.5 0 0 0-2-.7z"
            />
            <path
                fill="#85340a"
                d="M317.2 251.6c-.8 0-1.8.2-3.4.6 3.7-.8 4.5.5 6.2.5 1.6 0 2.4-1.3 6.1-.5-4-1.2-4.9-.4-6.1-.4-.8 0-1.4-.3-2.8-.2z"
            />
            <path
                fill="#85340a"
                d="M314 252.2h-.8c4.3.5 2.3 3 6.8 3s2.5-2.5 6.8-3c-4.5-.4-3.1 2.3-6.8 2.3-3.5 0-2.4-2.3-6-2.3zm9.7 6.7a3.7 3.7 0 0 0-7.4 0 3.8 3.8 0 0 1 7.4 0z"
            />
            <path
                id="e"
                fill="#85340a"
                d="M303.4 234.3c4.7-4.1 10.7-4.8 14-1.7a8 8 0 0 1 1.5 3.5c.4 2.3-.3 4.8-2.1 7.4l.8.4a14.6 14.6 0 0 0 1.6-9.4 13.3 13.3 0 0 0-.6-2.3c-4.5-3.7-10.7-4-15.2 2z"
            />
            <path
                id="d"
                fill="#85340a"
                d="M310.8 233c2.7 0 3.3.7 4.5 1.7 1.2 1 1.9.8 2 1 .3.2 0 .8-.3.6-.5-.2-1.3-.6-2.5-1.6s-2.5-1-3.7-1c-3.7 0-5.7 3-6.2 2.8-.3-.2 2.1-3.5 6.2-3.5z"
            />
            <use width="100%" height="100%" transform="translate(-18.4)" />
            <circle id="f" cx="310.9" cy="236.3" r="1.9" fill="#85340a" />
            <path
                id="g"
                fill="#85340a"
                d="M305.9 237.5c3.5 2.7 7 2.5 9 1.3 2-1.3 2-1.7 1.6-1.7-.4 0-.8.4-2.4 1.3-1.7.8-4.1.8-8.2-.9z"
            />
        </svg>
    ),
    assign: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
                clipRule="evenodd"
            />
        </svg>
    ),
    addUser: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="25"
            viewBox="0 0 640 512"
            fill="currentColor"
        >
            <path d="M224 48a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 208A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 96h91.4c65.7 0 120.1 48.7 129 112H49.3c8.9-63.3 63.3-112 129-112zm0-48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3zM504 312c0 13.3 10.7 24 24 24s24-10.7 24-24V248h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H552V136c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H440c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
        </svg>
    ),
    bolt: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
            />
        </svg>
    ),
    boltSlash: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.412 15.655 9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457 3 3m5.457 5.457 7.086 7.086m0 0L21 21"
            />
        </svg>
    ),
    building: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
            />
        </svg>
    ),
    bulk: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
        </svg>
    ),
    bookmark: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
        </svg>
    ),
    checked: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    ),
    'checked-spongy': (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
            style={{ transform: 'scale(.9)', cursor: 'help', color: 'var(--color-action)' }}
        >
            <path
                fillRule="evenodd"
                d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
            />
        </svg>
    ),
    cl: (
        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-cl" viewBox="0 0 640 480">
            <defs>
                <clipPath id="cl-a">
                    <path fillOpacity=".7" d="M0 0h682.7v512H0z" />
                </clipPath>
            </defs>
            <g fillRule="evenodd" clipPath="url(#cl-a)" transform="scale(.9375)">
                <path fill="#fff" d="M256 0h512v256H256z" />
                <path fill="#0039a6" d="M0 0h256v256H0z" />
                <path
                    fill="#fff"
                    d="M167.8 191.7 128.2 162l-39.5 30 14.7-48.8L64 113.1l48.7-.5L127.8 64l15.5 48.5 48.7.1-39.2 30.4 15 48.7z"
                />
                <path fill="#d52b1e" d="M0 256h768v256H0z" />
            </g>
        </svg>
    ),
    clipboard: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
            />
        </svg>
    ),
    close: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#5B5B5B"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    ),
    currencyDollar: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    ),
    delete: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="var(--color-error)"
        >
            <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
            />
        </svg>
    ),
    desktop: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
            />
        </svg>
    ),
    details: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
            />
        </svg>
    ),
    database: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                stroke="currentColor"
                d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
            />
        </svg>
    ),
    download: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
        </svg>
    ),
    edit: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
        </svg>
    ),
    'email-check': (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M13 19c0-3.31 2.69-6 6-6c1.1 0 2.12.3 3 .81V6a2 2 0 0 0-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h9.09c-.05-.33-.09-.66-.09-1M4 8V6l8 5l8-5v2l-8 5l-8-5m13.75 14.16l-2.75-3L16.16 18l1.59 1.59L21.34 16l1.16 1.41l-4.75 4.75"
            />
        </svg>
    ),
    'email-change': (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M3 4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h10.5a6.5 6.5 0 0 1-.5-2.5a6.5 6.5 0 0 1 6.5-6.5a6.5 6.5 0 0 1 1.5.18V6a2 2 0 0 0-2-2H3m0 2l8 5l8-5v2l-8 5l-8-5V6m16 6l-2.25 2.25L19 16.5V15a2.5 2.5 0 0 1 2.5 2.5c0 .4-.09.78-.26 1.12l1.09 1.09c.42-.63.67-1.39.67-2.21c0-2.21-1.79-4-4-4V12m-3.33 3.29c-.42.63-.67 1.39-.67 2.21c0 2.21 1.79 4 4 4V23l2.25-2.25L19 18.5V20a2.5 2.5 0 0 1-2.5-2.5c0-.4.09-.78.26-1.12l-1.09-1.09Z"
            />
        </svg>
    ),
    exclamation: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
            <path
                fill="currentColor"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
            />
        </svg>
    ),
    excel: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fillRule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z"
                clipRule="evenodd"
            />
        </svg>
    ),
    external: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
        </svg>
    ),
    eu: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="36.63139"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 23.444089 15.36"
        >
            <defs id="defs33" />

            <g id="g207" transform="scale(1.5263079)">
                <path
                    fill="#41479b"
                    strokeWidth={0.03}
                    d="M 14.20965,0 H 1.15035 C 0.51500999,0 0,0.51501009 0,1.1503498 V 8.9131489 C 0,9.5484597 0.51500999,10.0635 1.15035,10.0635 h 13.0593 c 0.63531,0 1.15035,-0.5150075 1.15035,-1.1503511 V 1.1503498 C 15.36,0.51501009 14.84499,0 14.20965,0 Z"
                    id="path2"
                />
                <g id="g28" transform="matrix(0.03,0,0,0.03,0,-2.6617704)">
                    <path
                        fill="#ffe15a"
                        d="m 259.59,126.684 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.457,-1.374 2.402,-1.374 2.86,0 z"
                        id="path4"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 259.59,354.547 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.457,-1.375 2.402,-1.375 2.86,0 z"
                        id="path6"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 373.521,240.615 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.457,-1.373 2.402,-1.373 2.86,0 z"
                        id="path8"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 145.658,240.615 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.458,-1.373 2.403,-1.373 2.86,0 z"
                        id="path10"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 162.762,181.119 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.458,-1.374 2.401,-1.374 2.86,0 z"
                        id="path12"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 360.096,295.05 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.382 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.458,-1.373 2.402,-1.373 2.86,0 z"
                        id="path14"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 318.395,139.417 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.102,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.087 3.54,-10.613 c 0.458,-1.373 2.401,-1.373 2.861,0 z"
                        id="path16"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 204.463,336.753 3.54,10.613 11.187,0.087 c 1.449,0.011 2.049,1.859 0.884,2.72 l -9,6.646 3.374,10.666 c 0.437,1.38 -1.135,2.524 -2.314,1.681 l -9.101,-6.506 -9.101,6.506 c -1.178,0.842 -2.751,-0.3 -2.314,-1.681 l 3.374,-10.666 -9,-6.646 c -1.165,-0.861 -0.565,-2.709 0.884,-2.72 l 11.187,-0.086 3.54,-10.613 c 0.458,-1.376 2.403,-1.376 2.86,-0.001 z"
                        id="path18"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 357.236,181.119 -3.54,10.613 -11.187,0.087 c -1.449,0.011 -2.049,1.859 -0.884,2.72 l 9,6.646 -3.374,10.666 c -0.437,1.38 1.135,2.524 2.314,1.681 l 9.101,-6.506 9.101,6.506 c 1.178,0.842 2.751,-0.3 2.314,-1.681 l -3.374,-10.666 9,-6.646 c 1.165,-0.861 0.565,-2.709 -0.884,-2.72 l -11.187,-0.087 -3.54,-10.613 c -0.458,-1.374 -2.402,-1.374 -2.86,0 z"
                        id="path20"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 159.902,295.05 -3.54,10.613 -11.187,0.087 c -1.449,0.011 -2.049,1.859 -0.884,2.72 l 9,6.646 -3.374,10.666 c -0.437,1.382 1.135,2.524 2.314,1.681 l 9.101,-6.506 9.101,6.506 c 1.178,0.842 2.751,-0.3 2.314,-1.681 l -3.374,-10.666 9,-6.646 c 1.165,-0.861 0.565,-2.709 -0.884,-2.72 l -11.187,-0.087 -3.54,-10.613 c -0.459,-1.373 -2.402,-1.373 -2.86,0 z"
                        id="path22"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 201.603,139.417 -3.54,10.613 -11.187,0.087 c -1.449,0.011 -2.049,1.859 -0.884,2.72 l 9,6.646 -3.374,10.666 c -0.437,1.38 1.135,2.524 2.314,1.681 l 9.101,-6.506 9.101,6.506 c 1.178,0.842 2.751,-0.3 2.314,-1.681 l -3.374,-10.666 9,-6.646 c 1.165,-0.861 0.565,-2.709 -0.884,-2.72 l -11.187,-0.087 -3.54,-10.613 c -0.459,-1.373 -2.402,-1.373 -2.86,0 z"
                        id="path24"
                    />
                    <path
                        fill="#ffe15a"
                        d="m 315.534,336.753 -3.54,10.613 -11.187,0.087 c -1.449,0.011 -2.049,1.859 -0.884,2.72 l 9,6.646 -3.374,10.666 c -0.437,1.38 1.135,2.524 2.314,1.681 l 9.101,-6.506 9.101,6.506 c 1.178,0.842 2.751,-0.3 2.314,-1.681 l -3.374,-10.666 9,-6.646 c 1.165,-0.861 0.565,-2.709 -0.884,-2.72 l -11.187,-0.086 -3.54,-10.613 c -0.459,-1.376 -2.402,-1.376 -2.86,-0.001 z"
                        id="path26"
                    />
                </g>
            </g>
        </svg>
    ),
    facilitator: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 25.912 25.912"
            fill="currentColor"
        >
            <path
                id="Medal_icon"
                data-name="Medal icon"
                d="M4.324-5.633l-3.5-5.831A1.616,1.616,0,0,0-.564-12.25H-6.189a.81.81,0,0,0-.664,1.274l5.631,8.045A10.474,10.474,0,0,1,4.324-5.633ZM18.1-12.25H12.475a1.619,1.619,0,0,0-1.389.786l-3.5,5.831a10.477,10.477,0,0,1,5.545,2.7l5.631-8.044A.81.81,0,0,0,18.1-12.25ZM5.956-4.153a8.907,8.907,0,1,0,8.907,8.907A8.907,8.907,0,0,0,5.956-4.153Zm4.682,7.959L8.719,5.677,9.173,8.32a.581.581,0,0,1-.843.612L5.956,7.684,3.582,8.931A.581.581,0,0,1,2.74,8.32l.454-2.643L1.274,3.806A.581.581,0,0,1,1.6,2.815l2.653-.387L5.435.023A.572.572,0,0,1,5.955-.3a.577.577,0,0,1,.523.323l1.186,2.4,2.653.387A.581.581,0,0,1,10.638,3.806Z"
                transform="translate(7 12.25)"
                fill="currentColor"
            />
        </svg>
    ),
    filter: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
            />
        </svg>
    ),
    home: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinejoin="round"
                strokeWidth="1"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
        </svg>
    ),
    integrations: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
            />
        </svg>
    ),
    image: (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.976 9.072h5.368l-5.368-5.367v5.367M6.144 2.241h7.808l5.856 5.855v11.711a1.952 1.952 0 0 1-1.952 1.952H6.145a1.951 1.951 0 0 1-1.952-1.952V4.192c0-1.083.868-1.951 1.952-1.951m0 17.567h11.71V12l-3.903 3.904L12 13.952l-5.856 5.856M8.096 9.073a1.952 1.952 0 0 0-1.952 1.952 1.952 1.952 0 0 0 1.952 1.951 1.952 1.952 0 0 0 1.952-1.951 1.952 1.952 0 0 0-1.952-1.952z"
                fill="#26a69a"
            />
        </svg>
    ),
    info: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
        </svg>
    ),
    intercom: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M21 0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18c1.658 0 3-1.342 3-3V3a3 3 0 0 0-3-3zm-5.801 4.399c0-.44.36-.8.802-.8c.44 0 .8.36.8.8v10.688a.802.802 0 0 1-1.602 0V4.399zM11.2 3.994a.8.8 0 0 1 1.6 0v11.602a.8.8 0 0 1-1.6 0V3.994zm-4 .405a.801.801 0 0 1 1.601 0v10.688a.801.801 0 0 1-1.601 0V4.399zM3.199 6A.801.801 0 0 1 4.8 6v7.195a.8.8 0 0 1-1.601 0V6zM20.52 18.202c-.123.105-3.086 2.593-8.52 2.593c-5.433 0-8.397-2.486-8.521-2.593a.8.8 0 0 1 1.039-1.218c.047.041 2.693 2.211 7.481 2.211c4.848 0 7.456-2.186 7.479-2.207a.798.798 0 0 1 1.128.086c.289.336.25.84-.086 1.128zm.281-5.007a.802.802 0 0 1-1.602 0V6a.802.802 0 0 1 1.602 0v7.195z"
            />
        </svg>
    ),
    login: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
            />
        </svg>
    ),
    lxp: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
            />
        </svg>
    ),
    mentor: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="15"
            viewBox="0 0 25 15"
            fill="currentColor"
        >
            <path
                id="Graduation_cap_icon"
                data-name="Graduation cap icon"
                d="M11.81-11.516.914-14.863a3.121,3.121,0,0,0-1.828,0l-10.9,3.348a.92.92,0,0,0,0,1.781l1.9.584a3.078,3.078,0,0,0-.7,1.832A1.234,1.234,0,0,0-11.25-6.25a1.227,1.227,0,0,0,.541,1l-1,4.487A.625.625,0,0,0-11.1,0H-8.9a.625.625,0,0,0,.61-.761l-1-4.487a1.227,1.227,0,0,0,.541-1A1.232,1.232,0,0,0-9.362-7.3a1.843,1.843,0,0,1,.808-1.434L-.914-6.387a3.124,3.124,0,0,0,1.828,0l10.9-3.348A.92.92,0,0,0,11.81-11.516ZM1.281-5.192a4.373,4.373,0,0,1-2.562,0l-5.665-1.74L-7.5-2.5C-7.5-1.119-4.142,0,0,0S7.5-1.119,7.5-2.5L6.946-6.932Z"
                transform="translate(12.5 15)"
                fill="currentColor"
            />
        </svg>
    ),
    minus: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
        </svg>
    ),
    'password-change': (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M12.63 2c5.53 0 10.01 4.5 10.01 10s-4.48 10-10.01 10c-3.51 0-6.58-1.82-8.37-4.57l1.58-1.25C7.25 18.47 9.76 20 12.64 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8C8.56 4 5.2 7.06 4.71 11h2.76l-3.74 3.73L0 11h2.69c.5-5.05 4.76-9 9.94-9m2.96 8.24c.5.01.91.41.91.92v4.61c0 .5-.41.92-.92.92h-5.53c-.51 0-.92-.42-.92-.92v-4.61c0-.51.41-.91.91-.92V9.23c0-1.53 1.25-2.77 2.77-2.77c1.53 0 2.78 1.24 2.78 2.77v1.01m-2.78-2.38c-.75 0-1.37.61-1.37 1.37v1.01h2.75V9.23c0-.76-.62-1.37-1.38-1.37Z"
            />
        </svg>
    ),
    pause: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    ),
    pdf: (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 9h5.5L13 3.5V9M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m4.93 10.44c.41.9.93 1.64 1.53 2.15l.41.32c-.87.16-2.07.44-3.34.93l-.11.04.5-1.04c.45-.87.78-1.66 1.01-2.4m6.48 3.81c.18-.18.27-.41.28-.66.03-.2-.02-.39-.12-.55-.29-.47-1.04-.69-2.28-.69l-1.29.07-.87-.58c-.63-.52-1.2-1.43-1.6-2.56l.04-.14c.33-1.33.64-2.94-.02-3.6a.853.853 0 0 0-.61-.24h-.24c-.37 0-.7.39-.79.77-.37 1.33-.15 2.06.22 3.27v.01c-.25.88-.57 1.9-1.08 2.93l-.96 1.8-.89.49c-1.2.75-1.77 1.59-1.88 2.12-.04.19-.02.36.05.54l.03.05.48.31.44.11c.81 0 1.73-.95 2.97-3.07l.18-.07c1.03-.33 2.31-.56 4.03-.75 1.03.51 2.24.74 3 .74.44 0 .74-.11.91-.3m-.41-.71.09.11c-.01.1-.04.11-.09.13h-.04l-.19.02c-.46 0-1.17-.19-1.9-.51.09-.1.13-.1.23-.1 1.4 0 1.8.25 1.9.35M7.83 17c-.65 1.19-1.24 1.85-1.69 2 .05-.38.5-1.04 1.21-1.69l.48-.31m3.02-6.91c-.23-.9-.24-1.63-.07-2.05l.07-.12.15.05c.17.24.19.56.09 1.1l-.03.16-.16.82z"
                fill="#f44336"
            />
        </svg>
    ),
    play: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={1.5}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    ),
    plus: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
    ),
    pulse: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.359"
            height="24.539"
            viewBox="0 0 16.359 24.539"
            fill="currentColor"
        >
            <path
                id="Lightning_icon"
                data-name="Lightning icon"
                d="M6.81-3.427H.564a.532.532,0,0,1-.458-.844l10.221-13.5a.566.566,0,0,1,1.017.377L10.1-7.981l5.652-.4a.535.535,0,0,1,.5.838L6.014,6.309A.564.564,0,0,1,5,5.907Z"
                transform="translate(0 18)"
                fill="currentColor"
            />
        </svg>
    ),
    profile: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={1.4}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    ),
    settings: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
        </svg>
    ),
    sync: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
        </svg>
    ),
    stats: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
            />
        </svg>
    ),
    tag: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
            />
        </svg>
    ),
    trending: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
        >
            <path
                fillRule="evenodd"
                d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z"
                clipRule="evenodd"
            />
        </svg>
    ),
    unAssign: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path
                fillRule="evenodd"
                d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                clipRule="evenodd"
            />
        </svg>
    ),
    unchecked: (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z" />
        </svg>
    ),
    unlock: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 576 512">
            <path
                opacity="1"
                fill="currentColor"
                d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z"
            />
        </svg>
    ),
    user: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                stroke="currentColor"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
        </svg>
    ),
    video: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
            />
        </svg>
    ),
    puzzle: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
            />
        </svg>
    ),
    warning: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
        </svg>
    ),
    widget: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M306.8 6.3C311.4 2.2 317.3 0 323.4 0c17.2 0 29.2 17.1 23.4 33.3L278.7 224H389c14.9 0 27 12.1 27 27c0 7.8-3.3 15.1-9.1 20.3L141.1 505.8c-4.5 4-10.4 6.2-16.5 6.2c-17.2 0-29.2-17.1-23.5-33.3L169.3 288H57.8C43.6 288 32 276.4 32 262.2c0-7.4 3.2-14.4 8.7-19.3L306.8 6.3zm.5 42.4L74.1 256H192c5.2 0 10.1 2.5 13.1 6.8s3.7 9.7 2 14.6L140.6 463.6 375.8 256H256c-5.2 0-10.1-2.5-13.1-6.8s-3.7-9.7-2-14.6l66.4-186z"
            />
        </svg>
    ),
}
