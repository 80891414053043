import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HistoryInterface, useRecent } from '../../Hooks/useRecent'
import { Section } from '../../Components/Section'

export const SearchHistory = ({ filter, title }: HistoryInterface) => {
    const { getHistory, saveHistory } = useRecent()
    const [searchHistory] = useState(getHistory({ filter }))
    if (!searchHistory.length) return null

    return (
        <>
            <Section title={title}>
                <div className="flex-column">
                    {searchHistory.map((item) => (
                        <Link
                            key={`${item.title}-${item.url}`}
                            to={item.url}
                            onClick={() => saveHistory(item)}
                        >
                            {item.title.split(`${filter} `)}
                        </Link>
                    ))}
                </div>
            </Section>
        </>
    )
}
