import { Icon } from './Icon'

export const ModalCloseButton = ({ onClose }: { onClose: () => void }) => {
    return (
        <Icon
            type="close"
            style={{ position: 'absolute', top: 16, right: 16, cursor: 'pointer' }}
            onClick={onClose}
        />
    )
}
