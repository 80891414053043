import React, { ReactNode } from 'react'
import Modal, { Styles } from 'react-modal'

const customStyles: Styles = {
    overlay: {
        backgroundColor: '#000000bd',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 350,
        minHeight: 115,
    },
}

export const Alert = ({ title, onConfirm, isOpen, setIsOpen }: Props) => {
    if (!isOpen) return null

    return (
        <Modal isOpen={isOpen} style={customStyles}>
            {typeof title === 'string' ? <h3 className="flex">{title}</h3> : title}
            <div className="flex" style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                <button className="danger" onClick={() => setIsOpen(false)}>
                    Cancelar
                </button>
                <button
                    className="success"
                    onClick={() => {
                        setIsOpen(false)
                        onConfirm()
                    }}
                >
                    Confirmar
                </button>
            </div>
        </Modal>
    )
}
// Interfaces
interface Props {
    title: string | ReactNode
    onConfirm: () => unknown
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
