import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { fetchProduct } from '../api'
import { Title } from '../../../Components/Title'
import { capitalize } from '../../../Helpers/formatters'
import { ProductDataForm } from '../Components/ProductDataForm'
import { useRecent } from '../../../Hooks/useRecent'

export const ProductManage = () => {
    const { saveHistory } = useRecent()
    let { id = '' } = useParams()
    const { data } = useQuery(['fetchProduct', id], () => fetchProduct(id))

    if (!data) return null

    const { name } = data.product

    saveHistory({ title: `Producto ${capitalize(name)}`, url: location.pathname })

    return (
        <>
            <Title title={capitalize(name)} />
            <ProductDataForm data={data.product} />
        </>
    )
}
