import { ProfilesStats } from '../../../Components/ProfilesStats'
import { getCohortStats } from '../api'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export const CohortStats = () => {
    const { id = '' } = useParams()

    const queryKey = ['Cohort Stats', id]
    const queryFn = () => getCohortStats(id)

    const { data: stats, isLoading } = useQuery({ queryKey, queryFn, staleTime: 1000 * 60 * 5 })

    if (isLoading) return <p>Cargando estadísticas de la cohorte, aguarde un momento por favor.</p>

    return <ProfilesStats stats={stats} />
}
