import { useState } from 'react'
import { ProfileMutationContent } from '../ProfileManage'

export const ProfilePayment = (data: ProfileMutationContent) => {
    const { _id, paidId, mutate } = data
    const [paidIdValue, setPaidIdValue] = useState(paidId ?? '')

    return (
        <section>
            <label>
                <span>Id de pago:</span>
                <input
                    style={{ width: '50ch' }}
                    name="paidId"
                    type="text"
                    value={paidIdValue}
                    onChange={({ target: { value } }) => setPaidIdValue(value)}
                />
            </label>
            <button
                className="success"
                onClick={() => mutate([_id, { paidId: paidIdValue }])}
                disabled={paidId === paidIdValue}
            >
                Agregar
            </button>
        </section>
    )
}
