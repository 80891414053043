import { App } from '../AppConfig'

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app'

// Add the Firebase products that you want to use
import { getAuth } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration

export const firebaseApp = initializeApp(App.firebaseConfig)

export const firebaseAuth = getAuth
