import { ModalCloseButton } from './ModalCloseButton'
import { MouseEventHandler } from 'react'
import ReactModal from 'react-modal'

export const Modal = ({
    onClose,
    onConfirm,
    onCancel,
    btnConfirmLabel = 'Confirmar',
    btnCancelLabel = 'Cancel',
    children,
    ...modalProps
}: ModalProps) => {
    return (
        <ReactModal
            onRequestClose={onClose}
            {...modalProps}
            style={{
                overlay: {
                    backgroundColor: 'rgb(0 0 0 / 56%)',
                    backdropFilter: 'blur(3px)',
                    ...modalProps.style?.overlay,
                },
                content: {
                    minWidth: 500,
                    width: 'fit-content',
                    height: 'fit-content',
                    inset: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '3em',
                    ...modalProps.style?.content,
                },
            }}
        >
            <ModalCloseButton onClose={onClose} />
            {children}
            {(onConfirm || onCancel) && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '2em',
                        marginTop: '2em',
                    }}
                >
                    {onCancel && (
                        <button className="outlined" onClick={onCancel}>
                            {btnCancelLabel}
                        </button>
                    )}
                    {onConfirm && (
                        <button className="primary" onClick={onConfirm}>
                            {btnConfirmLabel}
                        </button>
                    )}
                </div>
            )}
        </ReactModal>
    )
}

export type ModalProps = ReactModal.Props & {
    onClose: () => void
    onConfirm?: MouseEventHandler<HTMLButtonElement>
    btnConfirmLabel?: string
    onCancel?: MouseEventHandler<HTMLButtonElement>
    btnCancelLabel?: string
}
