import { AddEditRhythm, newFrequencyData } from './AddEditRhythm'
import { useNavigate, useParams } from 'react-router-dom'

import { AddEditCourse } from './AddEditCourse'
import { Frequency } from './Frequency'
import { Title } from '../../../Components/Title'
import { capitalize } from '../../../Helpers/formatters'
import { useCourse } from '../Hooks/useCourse'
import { useState } from 'react'
import { CourseCohorts } from './CourseCohorts'
import { CourseSubjects } from './CourseSubjects'
import { useOrganizations } from '../Hooks/useOrganizations'
import { copyToClipboard, getAppUrl } from '../../../Helpers/utilities'
import { CourseProfiles } from './CourseProfiles'
import { useUIContext } from '../../../Context/UIContext'

export const Course = () => {
    const { slug = '' } = useParams()
    const { showToast } = useUIContext()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const navigate = useNavigate()

    const { course, isLoading } = useCourse(slug)
    const { organizations } = useOrganizations()

    if (isLoading) return <p>Cargando...</p>

    if (!course) return <p className="danger">Curso no encontrado!</p>
    let referrer: string | undefined = undefined

    if (course.organizationId && organizations) {
        const referral = organizations.find((org) => org.id === course.organizationId)?.referral
        if (referral) referrer = `&referrer=${encodeURIComponent(referral)}`
    }

    const invitationLink = `${getAppUrl()}/registro?redirect=/marketplace/${
        course.slug ?? course.id
    }/enroll${referrer ? referrer : ''}`

    const copyInvitationLink = () => {
        copyToClipboard(invitationLink)
        showToast('El enlace de invitación ha sido copiado al portapapeles', 'info')
    }

    return (
        <>
            <Title
                title={capitalize(course?.title)}
                subtitle={[`Course ID: ${course.id}`, `Detalle ID: ${course?.detailId}`]}
            >
                <button className="outlined" onClick={() => navigate(`../${slug}/stats`)}>
                    STATS
                </button>

                <button className="outlined" onClick={() => setIsEditing(true)}>
                    EDITAR
                </button>

                {course.detailId && (
                    <button className="outlined" onClick={() => navigate('./details')}>
                        DETALLES
                    </button>
                )}

                <button className="info" onClick={copyInvitationLink}>
                    Invitar
                </button>

                <button className="primary" onClick={() => setIsOpen(true)}>
                    AGREGAR RITMO
                </button>
            </Title>

            <CourseSubjects />

            <CourseCohorts />

            <CourseProfiles />

            {course?.frequencies.map((frequency) => (
                <Frequency {...frequency} key={frequency.id} />
            ))}
            {!course?.frequencies.length && (
                <>
                    <p>
                        No hay ritmos configurados para esta red.{' '}
                        <button className="outlined" onClick={() => setIsOpen(true)}>
                            Puede agregar un ritmo haciendo click aquí.
                        </button>
                    </p>
                </>
            )}

            <AddEditRhythm
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                frequency={{ ...newFrequencyData, networkId: slug }}
            />

            <AddEditCourse
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                course={{ ...course }}
            />
        </>
    )
}
