import { useState } from 'react'
import { Icon } from '../../../../Components/Icon'
import { TextToClipboard } from '../../../../Components/TextToClipboard'
import { useUIContext } from '../../../../Context/UIContext'
import { signInLikeUser, getWidgetToken } from '../../api'
import { ProfileData } from '../ProfileManage'

import Styles from '../ProfileManage.module.css'
import { ApiWidget } from '../../../../infrastructure/api-widget'

const {
    REACT_APP_MEET_URL = location.origin.replace('backoffice', 'meet').replace('3005', '8087'),
} = process.env

export const ProfileLogin = ({
    _userId,
    _id: profileId,
    displayName,
    role,
    email,
    isAdmin,
}: ProfileData & { isAdmin: boolean }) => {
    const { showToast, showError } = useUIContext()

    const [lxpCode, setLxpCode] = useState<string>()

    const userID = _userId._id

    const openPlatform = () => {
        if (!userID) return
        signInLikeUser(userID).catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const openWidget = () => {
        if (!profileId) return
        getWidgetToken(profileId)
            .then(({ token, profile }) => {
                if (profile.role === 'student')
                    window.open(
                        `${process.env.REACT_APP_WIDGET_URL}?token=${token}`,
                        `widget-${profileId}`,
                        `
                        popup=yes,
                        left=100,
                        top=100,
                        width=350,
                        height=550,
                         `,
                    )
                else showToast(`No es usuario activo y no tiene acceso el widget.`, 'info')
            })
            .catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const openLXPWindow = (code: string) =>
        window.open(`${process.env.REACT_APP_LXP_URL}/login/admin?code=${code}`)

    const openLXP = () => {
        lxpCode
            ? openLXPWindow(lxpCode)
            : ApiWidget.post<LXPLoginResponse>('v1/lxp/backoffice/login', { email })
                  .then(({ data: { data } }) => {
                      setLxpCode(data.codigo)
                      openLXPWindow(data.codigo)
                  })
                  .catch((err) => showError(err))
    }

    const openMeet = () => {
        if (!profileId) return

        getWidgetToken(profileId)
            .then(({ token, profile }) => {
                if (profile.role === 'student')
                    window.open(
                        `${REACT_APP_MEET_URL}/${token}`,
                        `widget-${profileId}`,
                        `
                        popup=yes,
                        fullscreen=yes,
                        titlebar=no,
                        toolbar=no,
                        location=no,
                        status=no,
                        menubar=no,
                        scrollbars=no

                         `,
                    )
                else showToast(`No es usuario activo y no tiene acceso a meet.`, 'info')
            })
            .catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const handleCopyToken = () => {
        if (!profileId) return
        getWidgetToken(profileId)
            .then(({ token }) => {
                navigator.clipboard.writeText(token)
                showToast('Token copiado al portapapeles', 'success')
            })
            .catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const envLXP = !!process.env.REACT_APP_WIDGET_API && !!process.env.REACT_APP_LXP_URL
    const envWidget = !!process.env.REACT_APP_WIDGET_URL
    const envMeet = !!REACT_APP_MEET_URL

    if (!isAdmin)
        return (
            <section className={Styles.LoginSection}>
                ⛔ No tiene permisos para realizar esta acción.
            </section>
        )

    return (
        <>
            <section className={Styles.LoginSection}>
                {role === 'student' && (
                    <>
                        <Card
                            label="LXP"
                            type="lxp"
                            onClick={openLXP}
                            title={`Abrir LXP cómo ${displayName}`}
                            disabled={!envLXP}
                        />

                        <Card
                            label="Meet"
                            type="video"
                            onClick={openMeet}
                            title={`Abrir Meet cómo ${displayName}`}
                            disabled={!envMeet}
                        />

                        <Card
                            label="Widget"
                            type="widget"
                            onClick={openWidget}
                            title={`Abrir widget cómo ${displayName}`}
                            disabled={!envWidget}
                        />
                    </>
                )}
                <Card
                    label="Plataforma"
                    type="desktop"
                    onClick={openPlatform}
                    title={`Abrir plataforma cómo ${displayName}`}
                />
                <Card
                    label="Token"
                    type="login"
                    onClick={handleCopyToken}
                    title={`Copiar token de ${displayName}`}
                />
            </section>

            <section style={{ color: 'var(--color-success)', textAlign: 'center' }}>
                {lxpCode && (
                    <p>
                        Código de acceso a LXP: <TextToClipboard text={lxpCode} />
                    </p>
                )}
                {!envLXP && (
                    <div>Add REACT_APP_WIDGET_API and REACT_APP_LXP_URL to enable LXP login.</div>
                )}
                {!envWidget && <div>Add REACT_APP_WIDGET_URL to enable Widget login.</div>}
            </section>
        </>
    )
}

const Card = ({ label, title, onClick, disabled = false, ...rest }: CardProps) => (
    <button
        style={{
            width: 120,
            height: 80,
            border: '1px solid',
            display: 'grid',
            placeContent: 'center',
            padding: 20,
            gap: 5,
        }}
        onClick={onClick}
        title={title}
        disabled={disabled}
    >
        <Icon {...rest} style={{ margin: '0 auto' }} size="large" />
        <span>{label}</span>
    </button>
)

type IconProps = Parameters<typeof Icon>[0]

interface CardProps extends IconProps {
    label: string
    disabled?: boolean
    onClick?: () => void
}

interface LXPLoginResponse {
    data: {
        codigo: string
        message: string
        expirationDate: string
    }
    message: string
}
