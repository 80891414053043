import { UserProfile, trackProfile } from '../Pages/Users/api'
import { useMutation, useQueryClient } from 'react-query'

import { useUIContext } from '../Context/UIContext'

export const useTracking = () => {
    const { showError } = useUIContext()
    const queryClient = useQueryClient()

    const { mutate } = useMutation(
        (params: Parameters<typeof trackProfile>[0]) => trackProfile(params),
        {
            onMutate: ({ _id, tracking }) => {
                const queryKey = ['getProfileDetails', _id]
                const snapshot = queryClient.getQueryData<UserProfile>(['getProfileDetails', _id])
                snapshot &&
                    queryClient.setQueriesData(['getProfileDetails', _id], {
                        ...snapshot,
                        trackings: snapshot.trackings.concat(tracking),
                    })

                return { snapshot, _id, queryKey }
            },
            onError: (error, { _id }, snapshot) => {
                snapshot && queryClient.setQueryData(['getProfileDetails', _id], snapshot)
                showError(error)
            },
            onSettled: (data, _, { _id }) =>
                queryClient.invalidateQueries(['getProfileDetails', _id]),
        },
    )

    return { addTracking: mutate }
}
