import {
    EditScheduleDTO,
    NewScheduleDTO,
    addEditSchedule,
} from '../../../infrastructure/api-activation'
import { FormEvent, useState } from 'react'
import { Modal, ModalProps } from '../../../Components/Modal'

import DayJS from '../../../Helpers/DayJS'
import { useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'
import { Section } from '../../../Components/Section'
import { useParams } from 'react-router-dom'
import { generateTime } from '../../../Helpers/dateFormatters'

export const newScheduleData = {
    mongoId: '',
    from: 13,
    to: 15,
    fromMinutes: 0,
    toMinutes: 0,
    institution: '',
}

export const AddEditSchedule = ({
    schedule,
    onClose,
    ...modalProps
}: ModalProps & { schedule: NewScheduleDTO | EditScheduleDTO }) => {
    const { slug = '' } = useParams()
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setData] = useState(schedule)
    const isEditing = 'id' in data

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!data.mongoId) return showError('Complete los datos requeridos')

        addEditSchedule(data)
            .catch(showError)
            .then(onClose)
            .finally(() => {
                queryClient.invalidateQueries(['Course', 'Get', slug])
                isEditing && queryClient.invalidateQueries(['Schedule', data.id])
            })
    }

    const { start, end } = generateTime(data.from, data.to, data.fromMinutes, data.toMinutes)

    return (
        <Modal
            {...modalProps}
            onClose={onClose}
            onConfirm={() => onSubmit()}
            onCancel={() => onClose()}
        >
            <Section
                title={`${isEditing ? 'Editar' : 'Nuevo'} Horario`}
                style={{ margin: 0, minWidth: 600 }}
            >
                <form onSubmit={onSubmit}>
                    <label>
                        ID del curso en MongoDB:
                        <input
                            id="mongoId"
                            type="text"
                            name="mongoId"
                            pattern="^[a-fA-F0-9]{24}$"
                            value={data.mongoId}
                            onChange={({ target: { name, value } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <label>
                        Institución a la que los usuarios pertenecen:
                        <input
                            id="institution"
                            type="text"
                            name="institution"
                            value={data.institution}
                            onChange={({ target: { name, value } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <fieldset style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <legend>Horario de cursado en su zona horaria</legend>
                        <label>
                            Desde:
                            <input
                                type="time"
                                name="from"
                                id="from"
                                value={start}
                                onChange={({ target: { value } }) => {
                                    const [hours, minutes] = value.split(':')
                                    setData((prev) => ({
                                        ...prev,
                                        from: +DayJS().hour(+hours).minute(0).utc().format('HH'),
                                        fromMinutes: +DayJS().minute(+minutes).utc().format('mm'),
                                    }))
                                }}
                            />
                        </label>

                        <label>
                            Hasta:
                            <input
                                type="time"
                                name="to"
                                id="to"
                                value={end}
                                onChange={({ target: { value } }) => {
                                    const [hours, minutes] = value.split(':')
                                    setData((prev) => ({
                                        ...prev,
                                        to: +DayJS().hour(+hours).minute(0).utc().format('HH'),
                                        toMinutes: +DayJS().minute(+minutes).utc().format('mm'),
                                    }))
                                }}
                            />
                        </label>
                    </fieldset>
                </form>
            </Section>
        </Modal>
    )
}
