import React from 'react'
import { Column } from 'react-table'
import { Table } from '../../../Components/Table'
import { Link } from 'react-router-dom'
import { useOrganizations } from '../Hooks/useOrganizations'
import { OrganizationDTO } from '../../../infrastructure/api-activation'
import DayJS from '../../../Helpers/DayJS'
import { Section } from '../../../Components/Section'

export const OrganizationList = () => {
    const { organizations, status } = useOrganizations()
    const columns: Column<OrganizationDTO>[] = [
        {
            Header: 'Nombre',
            accessor: 'name',
        },
        {
            id: 'Logo',
            Header: 'Logo',
            accessor: ({ logo }) =>
                logo ? (
                    <div
                        style={{
                            width: 100,
                            height: 25,
                            backgroundImage: `url("${logo}")`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                ) : null,
        },
        {
            Header: 'Referral',
            accessor: 'referral',
        },
        {
            id: 'Activo',
            Header: 'Activo',
            accessor: ({ isActive }) => (isActive ? 'Si' : 'No'),
        },
        {
            Header: 'Marketplace',
            accessor: ({ marketplace }) => (
                <span
                    className={`tags ${
                        marketplace === 'open'
                            ? 'success'
                            : marketplace === 'closed'
                            ? 'info'
                            : 'error'
                    }`}
                >
                    {marketplace === 'open'
                        ? 'abierto'
                        : marketplace === 'closed'
                        ? 'cerrado'
                        : 'ninguno'}
                </span>
            ),
        },
        {
            Header: 'Antigüedad',
            accessor: ({ createdAt }) => DayJS(createdAt).fromNow(true),
        },
        {
            id: 'Options',
            accessor: ({ id, slug }) => (
                <div>
                    <Link to={`./${slug ?? id}`}>más...</Link>
                </div>
            ),
        },
    ]

    return (
        <Section title="Organizaciones">
            {status === 'error' ? (
                <p className="danger">No se pudieron cargar las organizaciones</p>
            ) : status === 'loading' ? (
                <p>Cargando organizaciones...</p>
            ) : status === 'success' && !organizations?.length ? (
                <p className="danger">No hay organizaciones configuradas</p>
            ) : status === 'success' && organizations?.length ? (
                <Table columns={columns} data={organizations} canDownloadCsv={false} />
            ) : null}
        </Section>
    )
}
