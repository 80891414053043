import Axios from 'axios'

export const downloadFile = (url: string, fileName = url) => {
    Axios.get(url, {
        responseType: 'blob',
        baseURL: `${location.origin}/`,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    })
}

export const downloadFileSearch = (url: string, fileName: string) => {
    Axios.post(url, {}, { responseType: 'arraybuffer' }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
    })
}

export const downloadCSV = (data: unknown[], fileName: string) => {
    const csv = data.join('\n')
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.click()
    link.remove()
}

export const dataURItoBlob = (dataURI: string) => {
    const byteString = window.atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) int8Array[i] = byteString.charCodeAt(i)
    return new Blob([int8Array], { type: 'application/pdf' })
}

export const download = async (uri: string) => {
    const blob = dataURItoBlob(uri)
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'certificate.pdf')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
