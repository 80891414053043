import {
    addEditOrganization,
    getAllOrganizationCourses,
    getAllOrganizations,
    getOneOrganizations,
} from '../../../infrastructure/api-activation'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useNavigate } from 'react-router-dom'
import { useUIContext } from '../../../Context/UIContext'

export const useOrganizations = () => {
    const queryKey = ['Organizations', 'Get', 'All']
    const queryFn = getAllOrganizations

    const { data: organizations = [], ...rest } = useQuery({ queryKey, queryFn })
    return { organizations, ...rest }
}

export const useOrganization = (slug?: number | string) => {
    const queryKey = ['Organization', 'Get', slug]
    const queryFn = () => getOneOrganizations(`${slug}`)

    const { data: organization, ...rest } = useQuery({ queryKey, queryFn, enabled: !!slug })
    return { organization, ...rest }
}

export const useUpdateOrganization = (id?: number | string) => {
    const navigate = useNavigate()
    const { showError } = useUIContext()
    const queryClient = useQueryClient()
    const mutationFn = (data: any) => addEditOrganization(`${id}`, data)

    return useMutation({
        mutationFn,
        onSuccess: (data) => navigate(`/ecosystem/${data.slug ?? data.id}`),
        onSettled: () => {
            queryClient.invalidateQueries(['Organizations', 'Get', 'All'])
            queryClient.invalidateQueries(['Organization'], { exact: false })
        },
        onError: showError,
    })
}

export const useOrganizationCourses = (slug?: number | string) => {
    const queryKey = ['Organization', 'Get', slug, 'Courses']
    const queryFn = () => getAllOrganizationCourses(`${slug}`)

    const { data: courses, ...rest } = useQuery({ queryKey, queryFn, enabled: !!slug })
    return { courses, ...rest }
}
