import { createRoot } from 'react-dom/client'
import { Suspense } from 'react'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Modal from 'react-modal'
const container = document.getElementById('root')!
const root = createRoot(container)
Modal.setAppElement('#root')

// Component
import { AppRouter } from './Router/AppRouter'
import { UIProvider } from './Context/UIContext'
import { AuthProvider } from './Context/AuthContext'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: process.env.NODE_ENV === 'production' },
    },
})

root.render(
    <QueryClientProvider client={queryClient}>
        <Suspense fallback={null}>
            <UIProvider>
                <AuthProvider>
                    <AppRouter />
                </AuthProvider>
            </UIProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </Suspense>
    </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
