import React from 'react'
import { Icon } from './Icon'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        timer: any
    }
}

export const Editable = ({
    children,
    onEdit,
    styles = {},
}: {
    children: string
    // eslint-disable-next-line no-unused-vars
    onEdit?: (text: string) => unknown
    styles?: any
}) => {
    if (!onEdit) return <>{children}</>

    const debounce = (cb: Function, milliseconds = 1000) => {
        window.timer && clearTimeout(window.timer)
        window.timer = setTimeout(cb, milliseconds)
    }

    const handleLabelChange = (text: string) => onEdit && debounce(() => onEdit(text))

    return (
        <span
            contentEditable={!!onEdit}
            suppressContentEditableWarning={true}
            onInput={({ target }) => handleLabelChange((target as HTMLSpanElement).innerText)}
        >
            {children}
            <Icon type="edit" style={{ fontSize: 15, marginLeft: 15, opacity: '.6', ...styles }} />
        </span>
    )
}
