import { ApiActivation } from '../../infrastructure/api-activation'

export const getCohort = (id: string) =>
    ApiActivation.get<GetCohorts>(`/admin/cohorts/${id}`).then(({ data }) => data.data)

export const getCohortStats = (id: string) =>
    ApiActivation.get<Stats>(`/admin/cohorts/${id}/stats`).then(({ data }) => data.data)

export const syncCohort = (id: string) =>
    ApiActivation.post<SyncCohorts>(`/admin/cohorts/${id}/sync`).then(({ data }) => data.data)

export const bulkCohort = ({ id, users }: BulkProps) =>
    ApiActivation.post<BulkCohorts>(`/admin/cohorts/${id}/bulk`, { users }).then(
        ({ data }) => data.data,
    )

// Interfaces
type API<T> = { data: T; message: string; code: number; success: boolean }

type GetCohorts = API<ExtendedCohortDTO>
type SyncCohorts = API<{}>
type BulkCohorts = API<{
    enrolled: ProfileResponse[]
    reactivated: ProfileResponse[]
    errors: Error[]
}>

interface Error {
    email: string
    error: string
}

interface ProfileResponse {
    userId: string
    profileId: string
    message: string
}

export interface CohortDTO {
    courseId: string
    id: string
    taken: number
    limit: number
    active: boolean
    date: Date
    createdAt: Date
    updatedAt: Date
}

export type ExtendedCohortDTO = {
    courseId: string
    id: string
    taken: number
    limit: number
    active: boolean
    date: Date
    createdAt: Date
    updatedAt: Date
    course: {
        networkId: string
        frequencyId: string
        id: string
        mongoId: string
        institution: string
        from: number
        to: number
        createdAt: Date
        updatedAt: Date
        frequency: {
            networkId: string
            id: string
            title: string
            description: string
            sunday: boolean
            monday: boolean
            tuesday: boolean
            wednesday: boolean
            thursday: boolean
            friday: boolean
            saturday: boolean
        }
    }
    profiles: {
        userId: string
        courseId: string
        quotaId: string
        id: string
        mongoId: string
        active: boolean
        complete: boolean
        createdAt: Date
        updatedAt: Date
        user: {
            displayName?: string | null
            name: string
            lastname: string
            email: string
            phone: string
            country: {
                name: string
                iso3: string
            }
        }
    }[]
}

export interface ProfileDTO {
    userId: string
    courseId: string
    quotaId: string
    id: string
    mongoId: string
    active: boolean
    complete: boolean
    createdAt: Date
    updatedAt: Date
}

export interface ProfilesStatsDTO {
    items: number
    actives: Record<string, number>
    completed: Record<string, number>
    genres: Record<string, number>
    ages: Record<string, number>
    dates: Record<string, number>
    days: Record<string, number>
    countries: Record<string, number>
    provinces: Record<string, number>
    employment: Record<string, number>
    goal: Record<string, number>
    motivation: Record<string, number>
    technologyRelation: Record<string, number>
    roles: Record<string, number>
    acquisition: Record<string, number>
    institution: Record<string, number>
    timesMentor: Record<string | number, number>
    status: Record<string, number>
    sources: Record<string, number>
    referrers: Record<string, number>
}

export type Stats = API<ProfilesStatsDTO>

interface BulkProps {
    id: string
    users: {
        email: string
        institution?: string
        acquisitionType?: string
    }[]
}
