import { AddEditSchedule, newScheduleData } from './AddEditSchedule'

import { AddEditRhythm } from './AddEditRhythm'
import DayJS from '../../../Helpers/DayJS'
import { FrequencyDTO } from '../../../infrastructure/api-activation'
import { ScheduleList } from './ScheduleList'
import { useState } from 'react'
import { Section } from '../../../Components/Section'

export const Frequency = (props: FrequencyDTO) => {
    const {
        id,
        title,
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        courses,
        networkId,
    } = props

    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const day_of_course = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]
        .map((day, index) => (day ? DayJS().day(index).format(`dddd`) : null))
        .filter((day) => !!day)
        .join(', ')

    return (
        <>
            <Section
                title={`Ritmo: ${title}`}
                subtitle={[`_Dias de cursado: ${day_of_course}.`, `ID: ${id}`]}
                options={
                    <>
                        <button className="outlined" onClick={() => setIsEditing(true)}>
                            EDITAR
                        </button>
                        <button className="primary" onClick={() => setIsOpen(true)}>
                            AGREGAR HORARIO
                        </button>
                    </>
                }
            >
                <ScheduleList courses={courses} />
            </Section>

            <AddEditSchedule
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                schedule={{ ...newScheduleData, networkId, frequencyId: id }}
            />

            <AddEditRhythm
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                frequency={props}
            />
        </>
    )
}
