import { FormEvent, useState } from 'react'
import {
    FrequencyDTO,
    NewFrequencyDTO,
    addEditFrequency,
} from '../../../infrastructure/api-activation'
import { Modal, ModalProps } from '../../../Components/Modal'

import DayJS from '../../../Helpers/DayJS'
import { useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'
import { Section } from '../../../Components/Section'
import { useParams } from 'react-router-dom'

export const newFrequencyData: NewFrequencyDTO = {
    networkId: '',
    title: 'Título',
    description: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
}
const weekDays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const

export const AddEditRhythm = ({
    frequency,
    onClose,
    ...modalProps
}: ModalProps & { frequency: FrequencyDTO | NewFrequencyDTO }) => {
    const { slug = '' } = useParams()
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setData] = useState(frequency)

    const isEditing = 'id' in frequency

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()

        addEditFrequency(data)
            .catch(showError)
            .then(onClose)
            .finally(() => queryClient.invalidateQueries(['Course', 'Get', slug], { exact: false }))
    }

    return (
        <Modal
            {...modalProps}
            onClose={onClose}
            onConfirm={() => onSubmit()}
            onCancel={() => onClose()}
        >
            <Section
                style={{ margin: 0, minWidth: 800 }}
                title={`${isEditing ? 'Editar' : 'Agregar'} ritmo`}
            >
                <form onSubmit={onSubmit}>
                    <label>
                        Título del ritmo
                        <input
                            type="text"
                            name="title"
                            id="title"
                            value={data.title}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <br />
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gridTemplateRows: '20px 35px',
                            gap: '1rem',
                        }}
                    >
                        <span style={{ gridColumn: '1 / -1' }}>Días de cursado:</span>
                        {weekDays.map((day, idx) => (
                            <label key={day} style={{ textAlign: 'center', margin: 0 }}>
                                {DayJS().day(idx).format('ddd')}
                                <input
                                    type="checkbox"
                                    name={day}
                                    id={day}
                                    checked={data[day]}
                                    onChange={({ target: { name, checked } }) =>
                                        setData((prev) => ({ ...prev, [name]: checked }))
                                    }
                                />
                            </label>
                        ))}
                    </div>
                    <br />
                    <label>
                        Descripción del ritmo:
                        <textarea
                            name="description"
                            id="description"
                            rows={6}
                            value={data.description}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>
                </form>
            </Section>
        </Modal>
    )
}
