import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCourse } from '../Hooks/useCourse'
import { Title } from '../../../Components/Title'
import { capitalize } from '../../../Helpers/formatters'
import { useMutation, useQueryClient } from 'react-query'
import { updateNetworkDetails } from '../../../infrastructure/api-activation'
import { useUIContext } from '../../../Context/UIContext'
import { Section } from '../../../Components/Section'

export const CourseDetails = () => {
    const navigate = useNavigate()
    const { slug = '' } = useParams()
    const queryClient = useQueryClient()
    const { showToast, showError } = useUIContext()
    const { course, isLoading } = useCourse(slug)

    const [renderLongDesc, setRenderLongDesc] = useState(false)

    const { mutate } = useMutation({
        mutationFn: async (data: Parameters<typeof updateNetworkDetails>[1]) => {
            try {
                await updateNetworkDetails(slug, data)
                showToast('Detalles del curso actualizados...')
            } catch (error) {
                showError(error)
            }
            await queryClient.invalidateQueries(['Course', slug])
            await queryClient.invalidateQueries(['Courses'])
        },
    })

    if (isLoading) return <p>Cargando...</p>

    const details = course?.details

    return (
        <>
            <Title
                title={`${capitalize(course?.title)}`}
                subtitle={[`Details ID: ${course?.detailId}`]}
            >
                <button className="outlined" onClick={() => navigate(-1)}>
                    VOLVER
                </button>
            </Title>
            <Section title={`Detalles del curso`}>
                <div className="columns">
                    <label>
                        <span>Estado del curso</span>

                        <select
                            name="status"
                            id="status"
                            defaultValue={details?.status}
                            onChange={({ target: { name, value } }) => mutate({ [name]: value })}
                        >
                            <option value="Not started">No iniciado</option>
                            <option value="In progress">En progreso</option>
                            <option value="Done">Finalizado</option>
                        </select>
                    </label>

                    <label>
                        <span>Contentful career ID</span>
                        <input
                            id="contentful_id"
                            name="contentful_id"
                            type="text"
                            defaultValue={details?.contentful_id}
                            onBlur={({ target: { name, value, defaultValue } }) =>
                                defaultValue !== value && mutate({ [name]: value })
                            }
                        />
                    </label>

                    <label>
                        <span>
                            Precio en USD: <small>(0 para cursos gratis)</small>
                        </span>
                        <input
                            id="price_usd"
                            name="price_usd"
                            type="text"
                            defaultValue={details?.price_usd}
                            onBlur={({ target: { name, value, defaultValue } }) =>
                                defaultValue !== value && mutate({ [name]: +value })
                            }
                        />
                    </label>
                </div>
                <br />

                <label>
                    <span>
                        Descripción breve: <small>(300 caracteres aprox)</small>
                    </span>
                    <textarea
                        id="short_description"
                        name="short_description"
                        style={{ height: '20ch' }}
                        defaultValue={details?.short_description}
                        onBlur={({ target: { name, value, defaultValue } }) =>
                            value !== defaultValue && mutate({ [name]: value })
                        }
                    />
                </label>

                <br />

                <label>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                            Descripción completa: <small>(hasta 5.000 caracteres)</small>
                        </span>
                        <button
                            className="outlined"
                            onClick={() => setRenderLongDesc((prev) => !prev)}
                        >
                            {renderLongDesc ? 'Editar' : 'Visualizar'}
                        </button>
                    </div>
                    {!renderLongDesc ? (
                        <textarea
                            id="long_description"
                            name="long_description"
                            style={{ minHeight: '60ch', width: '100%' }}
                            defaultValue={details?.long_description}
                            onBlur={({ target: { name, value, defaultValue } }) =>
                                value !== defaultValue && mutate({ [name]: value })
                            }
                        />
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{ __html: details?.long_description }}
                            style={{ minHeight: '60ch' }}
                        />
                    )}
                </label>
            </Section>
        </>
    )
}
