import { Link } from 'react-router-dom'
import { Icon } from '../../../Components/Icon'
import { Title } from '../../../Components/Title'
import { useState } from 'react'
import { AddEditOrganizations } from './AddEditOrganizations'

export const EcosystemTitle = () => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Title title="Ecosystem">
                <Link to={`/ecosystem/courses/public`}>
                    <Icon type="unlock" title="Cursos públicos" />
                </Link>
                <Link to="/ecosystem/none">
                    <Icon type="exclamation" title="Cursos SIN organización" />
                </Link>
                <button className="primary" onClick={() => setIsOpen(true)}>
                    NUEVA ORGANIZACIÓN
                </button>
            </Title>
            <AddEditOrganizations isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
    )
}
