import Axios, { AxiosResponse } from 'axios'

const { REACT_APP_WEBSOCKET } = process.env

if (!REACT_APP_WEBSOCKET) throw 'REACT_APP_WEBSOCKET not found!'

const baseURL = `${REACT_APP_WEBSOCKET}/api`.replace('wss', 'https')

export const ApiSocket = Axios.create({ baseURL })

export const sendEvent = (data: SendEventData) =>
    ApiSocket.post<AxiosResponse<SendEventData>>('/events', data).then(({ data }) => data.data)

interface SendEventData {
    event: string
    to: string[]
    except: any[]
    payload: Record<string, any>
}
