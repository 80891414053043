import { ProfileManage, ProfileManager } from '../Pages/Users/Components/ProfileManage'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'

// Styles
import Styles from './UIContext.module.css'
import { handleMessageError } from '../Helpers/error'

const defaultToast: ToastProps = {
    message: null,
    delay: 3,
    type: 'success',
    random: Date.now(),
}

const Context = createContext({} as ContextValues)
Context.displayName = 'UIContext'

// Hook
export const useUIContext = () => useContext(Context)

// HOC
export const UIProvider = ({ children }: { children: ReactNode }) => {
    const [profileManage, setProfileManage] = useState<ProfileManager>(null)
    const [toast, setToast] = useState(defaultToast)

    useEffect(() => {
        const timeOut = setTimeout(clearToast, toast.delay * 1000)
        return () => {
            clearTimeout(timeOut)
        }
    }, [toast])

    const showToast = (
        message: ToastProps['message'],
        type: ToastProps['type'] = 'success',
        delay: ToastProps['delay'] = 3,
    ) => {
        if (typeof message !== 'string') message = String(message)
        setToast({
            message,
            delay,
            type,
            random: Date.now(),
        })
    }

    const showError = (error: unknown) => showToast(handleMessageError(error), 'error', 5)

    const clearToast = () => setToast(defaultToast)

    return (
        <Context.Provider value={{ showToast, setProfileManage, showError, clearToast }}>
            {children}
            {toast.message && (
                <div
                    className={`${Styles.toastNotification} ${Styles[toast.type]}`}
                    onClick={() => setToast(defaultToast)}
                    aria-hidden="true"
                >
                    {toast.message}
                </div>
            )}
            {profileManage && <ProfileManage {...profileManage} />}
        </Context.Provider>
    )
}

// Interfaces
interface ContextValues {
    showToast: (
        // eslint-disable-next-line no-unused-vars
        message: ToastProps['message'],
        // eslint-disable-next-line no-unused-vars
        type?: ToastProps['type'],
        // eslint-disable-next-line no-unused-vars
        delay?: ToastProps['delay'],
    ) => void
    setProfileManage: React.Dispatch<React.SetStateAction<ProfileManager>>
    // eslint-disable-next-line no-unused-vars
    showError: (error: unknown) => void
    clearToast: () => void
}

interface ToastProps {
    message: string | null
    delay: number
    type: 'success' | 'error' | 'info'
    random: number
}
