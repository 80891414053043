import React, { useMemo } from 'react'
import { useCourseSubjects } from '../Hooks/useCourse'
import { useParams } from 'react-router-dom'
import { CourseSubjectDTO } from '../../../infrastructure/api-activation'
import { Column } from 'react-table'
import { Section } from '../../../Components/Section'
import { Table } from '../../../Components/Table'

export const CourseSubjects = () => {
    const { slug } = useParams()
    const { subjects, status } = useCourseSubjects(slug)

    const columns = useMemo(
        () =>
            [
                {
                    id: 'environment',
                    Header: 'Entorno',
                    accessor: ({ environment }) => environment?.name,
                },
                {
                    id: 'path',
                    Header: 'Camino',
                    accessor: ({ path }) => path?.name,
                },
                {
                    id: 'level',
                    Header: 'Nivel',
                    accessor: ({ level }) => level?.name,
                },
                {
                    id: 'number_order',
                    Header: 'Orden',
                    accessor: ({ number_order }) => number_order,
                },
            ] as Column<CourseSubjectDTO>[],
        [],
    )
    return (
        <Section title="Rutas">
            {status === 'error' ? (
                <p className="danger">No se pudieron cargar las rutas del curso</p>
            ) : status === 'loading' ? (
                <p>Cargando rutas del curso...</p>
            ) : status === 'success' && !subjects?.length ? (
                <p className="danger">No hay rutas configuradas para el curso</p>
            ) : status === 'success' && subjects?.length ? (
                <Table columns={columns} data={subjects} canDownloadCsv={false} />
            ) : null}
        </Section>
    )
}
