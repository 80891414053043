import { FrequencyDTO, getSchedule } from '../../../infrastructure/api-activation'

import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { Table } from '../../../Components/Table'
import { fromUTCToLocalRange } from '../../../Helpers/dateFormatters'
import { useQueries } from 'react-query'
import { useMemo } from 'react'

export const ScheduleList = ({ courses }: { courses: FrequencyDTO['courses'] }) => {
    const coursesQueries = useQueries(
        courses.map(({ id }) => ({
            queryKey: ['Schedule', id],
            queryFn: () => getSchedule(id),
            staleTime: 5 * 60 * 1000,
        })),
    )

    const columns = useMemo(
        () =>
            [
                {
                    id: 'Horario',
                    Header: 'Horario',
                    accessor: ({ from, to, fromMinutes, toMinutes }) =>
                        fromUTCToLocalRange(from, to, fromMinutes, toMinutes),
                },
                { Header: 'Institución', accessor: 'institution' },
                {
                    Header: 'Mongo ID',
                    accessor: ({ mongoId }) => <Link to={`/team/${mongoId}/staff`}>{mongoId}</Link>,
                },

                {
                    id: 'Cohortes',
                    Header: 'Cohortes',
                    accessor: ({ id }) =>
                        coursesQueries.find(({ data }) => data?.id === id)?.data?.quotas.length ??
                        0,
                },
                {
                    Header: '',
                    id: 'options',
                    accessor: ({ id }) => (
                        <div className="flex">
                            <Link to={`../schedules/${id}`}>más...</Link>
                        </div>
                    ),
                },
            ] as Column<FrequencyDTO['courses'][number]>[],
        [],
    )

    if (!courses.length)
        return <p className="danger">No hay horarios configurados para este ritmo.</p>
    return <Table data={courses} columns={columns} canDownloadCsv={false} className="flat" />
}
