import { useQuery, useQueryClient } from 'react-query'
import { getCourseById, getSubjectsByCourseSlug } from '../../../infrastructure/api-activation'

export const useCourse = (slug?: Parameters<typeof getCourseById>[number]) => {
    const queryClient = useQueryClient()
    const queryKey = ['Course', 'Get', slug]

    const prefetch = (networkId: string) =>
        queryClient.prefetchQuery({ queryKey, queryFn: () => getCourseById(networkId) })

    const { data: course, ...rest } = useQuery({
        queryKey,
        queryFn: () => getCourseById(`${slug}`),
        enabled: !!slug,
    })

    return { course, prefetch, ...rest }
}

export const useCourseSubjects = (slug?: Parameters<typeof getCourseById>[number]) => {
    const queryKey = ['Course', 'Get', slug, 'Subjects']
    const queryFn = () => getSubjectsByCourseSlug(`${slug}`)

    const { data: subjects, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!slug,
        staleTime: 5 * 60 * 1000,
    })

    return { subjects, ...rest }
}
