import '../../infrastructure/api-activation'

import { DescendantRoutes, RoutesType } from '../../Router/DescendantRoutes'

import { Course } from './Components/Course'
import { CourseStats } from './Components/CourseStats'
import { EcosystemHome } from './EcosystemHome'
import { PermissionDenied } from '../PermissionDenied/PermissionDenied'
import { Schedule } from './Components/Schedule'
import { CourseDetails } from './Components/CourseDetails'
import { Organization } from './Components/Organization'
import { CohortLayout } from '../Cohort/CohortLayout'
import { Cohort } from '../Cohort/Cohort'
import { CohortBulk } from '../Cohort/Components/CohortBulk'
import { CohortStats } from '../Cohort/Components/CohortStats'
import { PublicCoursesList } from './Components/PublicCoursesList'

export const EcosystemRoutes = () => {
    const routes: RoutesType = [
        {
            path: '/',
            role: 'admin',
            element: <EcosystemHome />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: ':slug',
            role: 'admin',
            element: <Organization />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'schedules/:id',
            role: 'admin',
            element: <Schedule />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: ':id/stats',
            role: 'admin',
            element: <CourseStats />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'courses/public',
            role: 'admin',
            element: <PublicCoursesList />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'courses/:slug',
            role: 'admin',
            element: <Course />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'courses/:slug/details',
            role: 'admin',
            element: <CourseDetails />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'cohorts/:id/staff',
            role: 'admin',
            element: (
                <CohortLayout section="Usuarios">
                    <Cohort />
                </CohortLayout>
            ),
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'cohorts/:id/stats',
            role: 'admin',
            element: (
                <CohortLayout section="Estadísticas">
                    <CohortStats />
                </CohortLayout>
            ),
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'cohorts/:id/bulk',
            role: 'admin',
            element: (
                <CohortLayout section="Alta masiva">
                    <CohortBulk />
                </CohortLayout>
            ),
            exitRoute: <PermissionDenied />,
        },
    ]
    return <DescendantRoutes propsElements={routes} />
}
