import React, { useState, useRef, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { ProductFromApi, searchProducts, fetchProduct } from '../api'
import { Column } from 'react-table'
import { Filter, Table } from '../../../Components/Table'
import { Tag } from '../../../Components/Tag'
import { Link } from 'react-router-dom'
import { Constants } from '../../../Helpers/constants'
import { useUIContext } from '../../../Context/UIContext'
import { Icon, IconTypes } from '../../../Components/Icon'

export const ProductSearch = () => {
    const [skip] = useState(0)
    const [limit] = useState(100)
    const { showToast } = useUIContext()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const urlParams = new URLSearchParams(location.search)
    const query = urlParams.get('query') || ''

    const [search, setSearch] = useState(query)
    const queryClient = useQueryClient()

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    const { data, isFetching, status } = useQuery(
        ['searchProducts', limit, skip, search],
        () => searchProducts(limit, skip, search),
        {
            onSuccess: (data) => {
                if (data.length === 0) {
                    showToast('No se encontraron resultados', 'info')
                }
            },
            onError: ({ response }) =>
                showToast(response.data.message || Constants.DEFAULT_ERROR_MESSAGE, 'error'),
            enabled: search.length >= 2, // Search only if have 2 characters
        },
    )

    // Updating the query search in the url
    useEffect(() => {
        if (search.length <= 2) return
        let params = new URLSearchParams(document.location.search.substring(1))
        params.set('query', search)
        window.history.replaceState({}, '', `${location.pathname}?${params}`)
    }, [search])

    // Pre fetching the next page of users when results are less than or equal to 3
    useEffect(() => {
        const results = data
        if (results && results.length <= 3)
            results?.forEach((product) =>
                queryClient.prefetchQuery(['fetchUser', product._id], () =>
                    fetchProduct(product._id),
                ),
            )
    }, [data, queryClient])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const { inputSearch } = event.target as SearchFrom
        setSearch(inputSearch.value)
    }

    const handleStatus = () => {
        switch (status) {
            case 'loading':
                return <div className="info">Cargando...</div>
            case 'success':
                return data && <Table data={data} columns={columns} />
        }
    }

    return (
        <div>
            <form onSubmit={(event) => handleSubmit(event)}>
                <fieldset>
                    <legend>Búsqueda de productos</legend>
                    <div className="flex">
                        <input
                            id="inputSearch"
                            name="inputSearch"
                            type="search"
                            autoComplete="on"
                            aria-label="Búsqueda de productos"
                            placeholder="Buscar"
                            defaultValue={query}
                            ref={inputRef}
                        />
                        <button type="submit" disabled={isFetching} name="submit">
                            Buscar
                        </button>
                    </div>
                </fieldset>
            </form>
            <br />
            {handleStatus()}
        </div>
    )
}

const columns: Column<ProductFromApi>[] = [
    {
        Header: 'Nombre',
        accessor: 'name',
    },
    {
        Header: 'Cuotas',
        accessor: 'installments',
        Filter,
    },
    {
        Header: 'Precio',
        id: 'price',
        accessor: ({ currency, price }) => `${currency} ${new Intl.NumberFormat().format(price)}`,
    },
    {
        Header: 'País',
        id: 'country',
        accessor: ({ country }) => (
            <div>
                <Icon type={country as IconTypes} />
                <span> {country && country.toUpperCase()}</span>
            </div>
        ),
    },
    {
        Header: 'Estado',
        accessor: 'isActive',
        Cell: function isActive({ value }) {
            const role = value ? 'Active' : 'Disabled'
            const variant = value ? 'success' : 'error'
            return <Tag variant={variant} label={role} />
        },
    },
    {
        Header: '',
        id: 'options',
        accessor: ({ _id }) => ({ _id }),
        Cell: function options({ value }: { value: { _id: string } }) {
            const { _id } = value
            return (
                <div className="column-2">
                    <SmartLink productId={_id} />
                </div>
            )
        },
    },
]

const SmartLink = (productId: { productId: string }) => {
    const queryClient = useQueryClient()

    return (
        <Link
            onMouseEnter={() =>
                queryClient.prefetchQuery(['fetchProduct', productId.productId], () =>
                    fetchProduct(productId.productId),
                )
            }
            to={`/products/${productId.productId}`}
        >
            Más...
        </Link>
    )
}

// Interfaces
interface SearchFrom extends EventTarget {
    inputSearch: HTMLInputElement
}
