import { CohortBulk } from './CohortBulk'
import { Column } from 'react-table'
import DayJS from 'dayjs'
import { ExtendedCohortDTO } from '../api'
import { Section } from '../../../Components/Section'
import { Table } from '../../../Components/Table'
import { UserProfileIcon } from '../../Users/Components/ProfileManage'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { capitalize } from '../../../Helpers/formatters'
import { useMemo } from 'react'

DayJS.extend(advancedFormat)

DayJS.extend(advancedFormat)

export const ProfilesList = ({ profiles, date }: ExtendedCohortDTO) => {
    const columns = useMemo(
        () =>
            [
                {
                    id: 'Usuario',
                    Header: 'Usuario',
                    accessor: ({ user }) =>
                        user
                            ? capitalize(
                                  user.displayName
                                      ? user.displayName
                                      : `${user.name} ${user.lastname}`,
                              )
                            : 'N/D',
                },
                {
                    id: 'País',
                    Header: 'País',
                    accessor: ({ user: { country } }) => (country ? country.iso3 : 'N/D'),
                },
                {
                    id: 'Email',
                    Header: 'Email',
                    accessor: ({ user }) => (user ? user.email : 'N/D'),
                },
                {
                    id: 'Teléfono',
                    Header: 'Teléfono',
                    accessor: ({ user }) => (user ? user.phone : 'N/D'),
                },
                {
                    id: 'createdAt',
                    Header: 'Fecha de creación',
                    accessor: ({ createdAt }) => DayJS(createdAt).format(),
                },
                {
                    id: 'options',
                    Header: 'Opciones',
                    accessor: ({ mongoId }) => <UserProfileIcon _profileId={mongoId} />,
                },
            ] as Column<ExtendedCohortDTO['profiles'][number]>[],
        [],
    )

    return (
        <Section title="Usuarios de la cohorte">
            {!profiles.length ? (
                <>
                    <p className="danger">No hay usuarios en esta cohorte.</p>
                    <br />
                    <CohortBulk />
                </>
            ) : (
                <Table
                    data={profiles}
                    columns={columns}
                    csvFileName={`Estudiantes_Cohorte_${DayJS(date)
                        .format('LLLL')
                        .replace(',', '')}`}
                    initialState={{ pageSize: 150 }}
                />
            )}
        </Section>
    )
}
