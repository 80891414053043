import React from 'react'

export const NewProductDataForm = ({ handleChange }: Props) => {
    return (
        <div>
            <form className="column-2" onSubmit={(event) => event.preventDefault()}>
                <label htmlFor="name">
                    Título:
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
                <label htmlFor="installments">
                    Plazas Totales:
                    <input
                        type="number"
                        id="installments"
                        name="installments"
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
                <label htmlFor="strarDate">
                    Fecha de Inicio:
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
                <label htmlFor="endDate">
                    Fecha Final :
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        onChange={(event) => handleChange(event)}
                    />
                </label>
            </form>
        </div>
    )
}

interface Props {
    // eslint-disable-next-line
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => unknown
}
