import React, { ButtonHTMLAttributes, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useUIContext } from '../../../Context/UIContext'
import { Constants } from '../../../Helpers/constants'
import { ProductFromApi, updateProduct } from '../api'
import { ProductState } from './ProductState'

export const ProductDataForm = ({ data }: Props) => {
    const { showToast } = useUIContext()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [formChanges, setFormChanges] = useState<Partial<ProductFromApi>>({})
    const productModalState = useState(false)

    //Update product
    const { mutate } = useMutation(({ id }: Mutate) => updateProduct(id, formChanges), {
        onError: ({ response }) =>
            showToast(response.data.message || Constants.DEFAULT_ERROR_MESSAGE, 'error'),
        onSuccess: () => {
            setFormChanges({})
            showToast(Constants.DEFAULT_SUCCESS_MESSAGE, 'success')
            queryClient.invalidateQueries(['fetchProduct'], { exact: false })
            navigate(-1)
        },
    })

    if (!data) return null

    const { _id, name, price, tag, pipelineID, searchValue, isActive } = data

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, type } = event.target
        let { value } = event.target

        // Parsing dates from input to valid API date string
        if (type === 'date') value = new Date(value).toISOString()

        setFormChanges((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    return (
        <div>
            <form className="column-2" onSubmit={(event) => event.preventDefault()}>
                <input type="hidden" name="id" id="id" defaultValue={_id} />
                <label htmlFor="name">
                    Title:
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
                <label htmlFor="price">
                    Precio:
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={price}
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
                <label htmlFor="tag">
                    TAG:
                    <input
                        type="text"
                        id="tag"
                        name="tag"
                        value={tag}
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                    />
                </label>
                <label htmlFor="pipelineID">
                    Pipeline ID:
                    <input
                        type="number"
                        id="pipelineID"
                        name="pipelineID"
                        value={pipelineID}
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                    />
                </label>
                <label htmlFor="searchValue">
                    Search Value:
                    <input
                        type="text"
                        id="searchValue"
                        name="searchValue"
                        value={searchValue}
                        className="capitalize"
                        onChange={(event) => handleChange(event)}
                        required
                    />
                </label>
            </form>
            <button
                onClick={() => _id && mutate({ id: _id, data: formChanges })}
                className="success big"
                disabled={!Object.keys(formChanges).length}
            >
                Guardar
            </button>
            <ButtonState onClick={() => productModalState[1](true)} state={isActive} />
            <ProductState id={_id} isActive={isActive} modalState={productModalState} />
        </div>
    )
}

export const ButtonState = ({ type, onClick, state }: ButtonProps) => {
    return (
        <button type={type} className={state ? 'danger' : 'success'} onClick={onClick}>
            {state ? 'Inhabilitar' : 'Habilitar'}
        </button>
    )
}

//Interfaces
export interface Props {
    data: Partial<ProductFromApi>
}

export interface Mutate {
    id: string
    data: Partial<ProductFromApi>
}

export interface ButtonProps {
    type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
    state: boolean | undefined
    onClick: () => void
}
