import { FocusEvent, useMemo, useState } from 'react'
import { ScheduleWithQuotasDTO, editCohort } from '../../../infrastructure/api-activation'

import { AddCohort } from './AddCohort'
import { Column } from 'react-table'
import DayJS from '../../../Helpers/DayJS'
import { Link } from 'react-router-dom'
import { SliderCheckbox } from '../../../Components/SliderCheckbox'
import { Table } from '../../../Components/Table'
import { useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'
import { Section } from '../../../Components/Section'

export const CohortsList = (props: ScheduleWithQuotasDTO & { scheduleId: string }) => {
    const { showError } = useUIContext()
    const queryClient = useQueryClient()
    const [isOpen, setIsOpen] = useState(false)

    const { quotas, from, fromMinutes } = props

    const startSchedule = props.quotas.length
        ? DayJS(props.quotas.at(-1)?.date)
              .add(7, 'days')
              .toDate()
        : DayJS.utc().add(1, 'day').hour(from).minute(fromMinutes).second(0).toDate()

    const columns = useMemo(
        () =>
            [
                {
                    id: 'date',
                    Header: 'Fecha de inicio',
                    accessor: ({ date }) => (
                        <span className={DayJS(date).isBefore(DayJS()) ? 'danger' : ''}>
                            {DayJS(date).format('LLLL')}
                        </span>
                    ),
                },
                {
                    id: 'Comienzo',
                    Header: 'Comienzo',
                    accessor: ({ date }) => DayJS(date).fromNow(),
                },
                {
                    id: 'Ocupación',
                    Header: 'Ocupación',
                    accessor: ({ limit, _count: { profiles } }) => {
                        const percentage = (profiles ? (profiles * 100) / limit : 0).toFixed(2)

                        return (
                            <label style={{ display: 'inline' }}>
                                <progress
                                    max={100}
                                    value={percentage}
                                    style={{ marginRight: 20, width: 100 }}
                                    title={`${percentage}% de ocupación`}
                                />
                                {percentage}%
                            </label>
                        )
                    },
                },
                {
                    id: '_count',
                    Header: 'Ocupados',
                    accessor: ({ _count }) => `${_count.profiles}`,
                },
                {
                    id: 'limit',
                    Header: 'Limite',
                    accessor: ({ id, limit, courseId }) => (
                        <EditCohortsLimit id={id} limit={limit} courseId={courseId} />
                    ),
                },
                {
                    id: 'Mostrar',
                    Header: 'Mostrar',
                    accessor: ({ id, courseId, active }) => (
                        <SliderCheckbox
                            checked={active}
                            onChange={() =>
                                editCohort({ id, data: { active: !active } })
                                    .catch(showError)
                                    .finally(() =>
                                        queryClient.invalidateQueries(['Schedule', courseId]),
                                    )
                            }
                        />
                    ),
                },
                {
                    id: 'options',
                    Header: 'Opciones',
                    accessor: ({ id }) => <Link to={`/ecosystem/cohorts/${id}/staff`}>más...</Link>,
                },
            ] as Column<ScheduleWithQuotasDTO['quotas'][number]>[],
        [],
    )

    return (
        <Section
            title="Cohortes"
            options={
                <button className="primary" onClick={() => setIsOpen(true)}>
                    AGREGAR COHORTE
                </button>
            }
        >
            <Table data={quotas} columns={columns} canDownloadCsv={false} />

            <AddCohort
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                courseId={props.scheduleId}
                date={startSchedule}
            />
        </Section>
    )
}

export const EditCohortsLimit = ({
    id,
    limit,
    courseId,
}: {
    id: string
    limit: number
    courseId: string
}) => {
    const { showError, showToast } = useUIContext()
    const queryClient = useQueryClient()
    const update = ({
        target: {
            validity: { valid },
            defaultValue,
            value,
        },
    }: FocusEvent<HTMLInputElement, Element>) => {
        if (!valid || value === defaultValue) return
        editCohort({ id, data: { limit: +value } })
            .then(() => showToast('Limite actualizado correctamente'))
            .catch(showError)
            .finally(() => queryClient.invalidateQueries(['Schedule', courseId]))
    }

    return (
        <input
            type="number"
            name="limit"
            id="limit"
            defaultValue={limit}
            style={{ width: '8ch', textAlign: 'center' }}
            onBlur={update}
        />
    )
}
