import React, { useState } from 'react'
import { Modal, ModalProps } from '../../../Components/Modal'
import { OrganizationDTO } from '../../../infrastructure/api-activation'
import { Icon } from '../../../Components/Icon'
import { Switch } from '../../../Components/Switch'
import { useOrganization, useUpdateOrganization } from '../Hooks/useOrganizations'
import { Section } from '../../../Components/Section'
import { getAppUrl } from '../../../Helpers/utilities'

export const AddEditOrganizations = ({ id, onClose, ...modalProps }: Props) => {
    const { organization } = useOrganization(id)
    const { mutate } = useUpdateOrganization(id)

    const [showSettings, setShowSettings] = useState(false)
    const [domain, setDomain] = useState<string>()
    const [values, setValues] = useState<Partial<OrganizationDTO | undefined>>(organization)

    const thereAreChanges = JSON.stringify(values) !== JSON.stringify(organization)

    const onSubmit = () => {
        if (!thereAreChanges) return
        // eslint-disable-next-line no-unused-vars
        const { createdAt, updatedAt, ...updatedData } = values ?? {}
        mutate(updatedData)
        onClose()
    }

    const domains: string[] =
        values?.domain?.startsWith('[') && values?.domain?.endsWith(']')
            ? JSON.parse(values?.domain)
            : []

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ) => {
        e.preventDefault()
        let value: string | number | boolean = e.target.value
        const { name, type } = e.target
        if (type === 'number' || type === 'tel') value = Number(value)
        setValues((prev) => ({ ...prev, [name]: value }))
    }

    const invitationLink = `${getAppUrl()}/registro?referrer=${values?.referral}`

    const {
        logo,
        name,
        slug,
        isActive,
        enabled,
        referral,
        marketplace,
        description,
        apiKey,
        apiSecret,
        notifyUsersByEmail,
    } = values || {}

    return (
        <Modal {...modalProps} onClose={onClose} onCancel={onClose} onConfirm={() => onSubmit()}>
            <Section
                title={!id ? 'Nueva organización' : name}
                options={
                    <div className="right">
                        <button
                            className="outlined"
                            onClick={() => setShowSettings((prev) => !prev)}
                        >
                            <Icon type="settings" size="large" />
                        </button>
                    </div>
                }
                style={{ minWidth: 900, margin: 0 }}
            >
                <form onSubmit={onSubmit}>
                    {!showSettings ? (
                        <>
                            <div className="columns">
                                <label>
                                    Nombre
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Nombre de la organización"
                                        required={true}
                                        value={name}
                                        onChange={onChange}
                                    />
                                </label>

                                <label>
                                    Logo
                                    <input
                                        id="logo"
                                        name="logo"
                                        type="url"
                                        placeholder="Logo de la organización"
                                        value={logo}
                                        onChange={onChange}
                                    />
                                </label>
                            </div>
                            <div className="columns">
                                <label>
                                    Slug
                                    <input
                                        id="slug"
                                        name="slug"
                                        type="text"
                                        placeholder="Slug de la organización"
                                        required={true}
                                        value={slug}
                                        readOnly={!!organization?.slug}
                                        onChange={onChange}
                                    />
                                </label>

                                <label>
                                    Tipo de marketplace
                                    <select
                                        id="marketplace"
                                        name="marketplace"
                                        required={true}
                                        value={marketplace}
                                        onChange={onChange}
                                    >
                                        <option value="none">Ninguno</option>
                                        <option value="closed">Cerrado</option>
                                        <option value="open">Abierto</option>
                                    </select>
                                </label>
                            </div>

                            <div className="columns">
                                <fieldset>
                                    <legend>Dominos de la organización</legend>

                                    {domains.map((domain) => (
                                        <DomainItem
                                            key={domain}
                                            domain={domain}
                                            onDelete={() => {
                                                setValues((prev) =>
                                                    prev
                                                        ? {
                                                              ...prev,
                                                              domain: JSON.stringify(
                                                                  domains.filter(
                                                                      (d) => d !== domain,
                                                                  ),
                                                              ),
                                                          }
                                                        : prev,
                                                )
                                            }}
                                        />
                                    ))}
                                    <label>
                                        <input
                                            id="domain"
                                            name="domain"
                                            type="text"
                                            placeholder="Nuevo dominio"
                                            value={domain}
                                            onChange={(e) => setDomain(e.target.value)}
                                        />
                                        <button
                                            onClick={() => {
                                                setValues((prev) =>
                                                    prev
                                                        ? {
                                                              ...prev,
                                                              domain: JSON.stringify([
                                                                  ...domains,
                                                                  domain,
                                                              ]),
                                                          }
                                                        : {
                                                              domain: JSON.stringify([domain]),
                                                          },
                                                )
                                                setDomain('')
                                            }}
                                            className="success"
                                            style={{ margin: 0 }}
                                            disabled={!domain}
                                        >
                                            Agregar
                                        </button>
                                    </label>
                                </fieldset>

                                <fieldset>
                                    <legend>Enlace para referir</legend>
                                    <label>
                                        Referral
                                        <input
                                            id="referral"
                                            name="referral"
                                            type="text"
                                            placeholder="Referral de la organización"
                                            readOnly={!!id}
                                            value={referral}
                                            onChange={onChange}
                                        />
                                    </label>
                                    {referral && (
                                        <p>
                                            <a
                                                href={invitationLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {invitationLink}
                                            </a>
                                        </p>
                                    )}
                                </fieldset>
                            </div>

                            <label>
                                Descripción
                                <textarea
                                    id="description"
                                    name="description"
                                    placeholder="Descripción de la organización"
                                    value={description}
                                    onChange={onChange}
                                    style={{ height: '10ch' }}
                                />
                            </label>
                        </>
                    ) : (
                        <>
                            <div className="columns">
                                <Switch
                                    id="isActive"
                                    name="isActive"
                                    label="Organización Activa"
                                    onClick={() =>
                                        setValues((prev) =>
                                            prev ? { ...prev, isActive: !prev.enabled } : prev,
                                        )
                                    }
                                    checked={isActive}
                                />
                                <Switch
                                    id="enabled"
                                    name="enabled"
                                    label="Organización Habilitada"
                                    onClick={() =>
                                        setValues((prev) =>
                                            prev ? { ...prev, enabled: !prev.enabled } : prev,
                                        )
                                    }
                                    checked={enabled}
                                />

                                <Switch
                                    id="notifyUsersByEmail"
                                    name="notifyUsersByEmail"
                                    label="Notificar usuarios vía email"
                                    onClick={() =>
                                        setValues((prev) =>
                                            prev
                                                ? {
                                                      ...prev,
                                                      notifyUsersByEmail: !prev.notifyUsersByEmail,
                                                  }
                                                : prev,
                                        )
                                    }
                                    checked={notifyUsersByEmail}
                                />
                            </div>
                            <fieldset>
                                <legend>Acceso a APIs</legend>

                                <div className="columns">
                                    <label>
                                        API Key
                                        <input
                                            id="apiKey"
                                            name="apiKey"
                                            type="text"
                                            placeholder="API Key"
                                            required={true}
                                            value={apiKey}
                                            onChange={onChange}
                                        />
                                    </label>

                                    <label>
                                        API Secret
                                        <input
                                            id="apiSecret"
                                            name="apiSecret"
                                            type="text"
                                            placeholder="API Secret"
                                            required={true}
                                            value={apiSecret}
                                            onChange={onChange}
                                        />
                                    </label>
                                </div>
                            </fieldset>
                        </>
                    )}
                </form>
            </Section>
        </Modal>
    )
}

const DomainItem = ({ domain, onDelete }: { domain: string; onDelete: () => void }) => {
    return (
        <div className="columns">
            <a
                href={`//${domain}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex"
                style={{
                    display: 'flex',
                    padding: '0.1em 0px',
                    gap: '1em',
                    justifyContent: 'flex-start',
                }}
            >
                {domain}
            </a>
            <button className="outlined right" onClick={onDelete}>
                <Icon type="delete" />
            </button>
        </div>
    )
}

interface Props extends ModalProps {
    id?: string
}
