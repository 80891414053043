import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { UsersRoles } from '../Interfaces/users'
import { AuthRole } from './AppRouter'

export type RoutesType = RoutesProps[]

export interface RoutesProps {
    path: string
    role: UsersRoles | 'guest'
    element: React.ReactElement
    exitRoute: React.ReactNode
}

export const DescendantRoutes = ({ propsElements }: { propsElements: RoutesType }) => {
    const { hasAuth } = AuthRole()
    return (
        <Routes>
            {propsElements.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={hasAuth(route.role) ? route.element : route.exitRoute}
                />
            ))}
        </Routes>
    )
}
