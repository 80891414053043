import { Table, Filter } from '../../../Components/Table'
import { useOrganizationCourses } from '../Hooks/useOrganizations'
import { useMemo } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import DayJS from '../../../Helpers/DayJS'
import { Section } from '../../../Components/Section'

export const OrganizationCoursesList = ({ slug }: { slug: string; title?: string }) => {
    const { courses, status } = useOrganizationCourses(slug)

    const isOrphan = slug === 'none'

    const columns = useMemo(
        () =>
            [
                {
                    Header: 'Título',
                    accessor: ({ title, slug, id }) => (
                        <Link to={`/ecosystem/courses/${slug ?? id}`}>{title}</Link>
                    ),
                    Filter,
                },
                {
                    id: 'Slug',
                    Header: 'Slug',
                    accessor: 'slug',
                    Filter,
                },
                {
                    id: 'lang',
                    Header: 'Idioma',
                    accessor: ({ lang }) => (
                        <span style={{ textTransform: 'uppercase' }}>{lang}</span>
                    ),
                    Filter,
                },
                {
                    id: 'career',
                    Header: 'Carrera',
                    accessor: ({ path, level }) => (path ? [path, level].join('/') : '-'),
                },
                {
                    Header: 'Antigüedad',
                    accessor: ({ createdAt }) => (createdAt ? DayJS(createdAt).fromNow(true) : '-'),
                },
                {
                    id: 'visible',
                    Header: 'Listado',
                    accessor: ({ visible }) => (visible ? 'Si' : 'No'),
                    Filter,
                },
                {
                    id: 'public',
                    Header: 'Disponibilidad',
                    accessor: ({ public: isPublic }) => (
                        <span className={`tags ${isPublic ? 'success' : 'error'}`}>
                            {isPublic ? 'publico' : 'privado'}
                        </span>
                    ),
                },
            ] as Column<NonNullable<typeof courses>[number]>[],
        [],
    )

    return (
        <>
            <Section title={`Cursos`}>
                {status === 'error' ? (
                    <p className="danger">No se pudieron cargar los cursos.</p>
                ) : status === 'loading' ? (
                    <p>Cargando los cursos...</p>
                ) : status === 'success' && !courses?.length ? (
                    <p className="danger">
                        {isOrphan
                            ? 'No hay cursos sin organización asignada.'
                            : 'No hay cursos configurados para esta organización.'}
                    </p>
                ) : status === 'success' && courses?.length ? (
                    <Table columns={columns} data={courses} initialState={{ pageSize: 100 }} />
                ) : null}
            </Section>
        </>
    )
}
