import React, { useState } from 'react'
import { Title } from '../../../Components/Title'
import { SearchHistory } from '../../Home/SearchHistory'
import { NewProduct } from '../Components/NewProduct'
import { ProductSearch } from '../Components/ProductSearch'

export const ProductsSearchManage = () => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <Title title="Productos">
                <button className="action" onClick={() => setOpen(true)}>
                    Nuevo
                </button>
            </Title>
            {open && <NewProduct isOpen={open} setIsOpen={setOpen} />}
            <ProductSearch />
            <SearchHistory filter="Producto" title="Productos frecuentes" />
        </div>
    )
}
