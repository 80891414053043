import GoogleChart, { ReactGoogleChartProps } from 'react-google-charts'

export const ChartComponent = (props: ChartData) => (
    <GoogleChart {...useChartData(props)} width={'100%'} height={'400px'} />
)

const useChartData = ({ data, headers = ['Índices', 'Valores'], ...rest }: ChartData) => ({
    data: [headers, ...Object.entries(data)],
    ...rest,
})

export interface ChartData extends ReactGoogleChartProps {
    headers?: [string, string]
    data: Record<string, string | number>
}
