import React from 'react'
import { ProfileMutationContent } from '../ProfileManage'
import DayJS from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import es from 'dayjs/locale/es'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { quitReasons } from '../../../../AppConfig'
import { capitalize } from '../../../../Helpers/formatters'
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.locale(es)

export const ProfileDetails = ({
    _userId,
    _id,
    _classId,
    displayName,
    urlImage,
    progressiveSchedule,
    institution,
    acquisitionType,
    quitReason,
    content,
    role,
    mutate,
    mutateContent,
}: ProfileMutationContent) => {
    const { algorithmType, courseDuration } = _classId ?? {}
    const { startDateCourse, endDateCourse } = courseDuration ?? {}
    const { start } = progressiveSchedule ?? {}
    const { day, description, message } = content ?? {}

    const isHCA4 = algorithmType === 'hca4'

    const updateProfileImage = () => {
        const src = prompt('URL de la imagen de perfil') ?? ''
        src !== urlImage && mutate([_id, { urlImage: src }])
    }

    const minStart = start
        ? DayJS(start).format('YYYY-MM-DDTHH:mm')
        : startDateCourse
        ? DayJS(startDateCourse).format('YYYY-MM-DDTHH:mm')
        : undefined

    const maxStart = endDateCourse ? DayJS(endDateCourse).format('YYYY-MM-DDTHH:mm') : undefined

    return (
        <section style={{ display: 'grid', gridTemplateColumns: '1fr 150px', gap: '2em' }}>
            <div>
                {isHCA4 && role === 'student' && (
                    <>
                        <div className="columns">
                            <label>
                                Fecha de inicio de cursado:
                                <input
                                    name="start"
                                    type="datetime-local"
                                    min={minStart}
                                    max={maxStart}
                                    defaultValue={
                                        progressiveSchedule?.start
                                            ? DayJS(progressiveSchedule?.start).format(
                                                  'YYYY-MM-DDTHH:mm',
                                              )
                                            : ''
                                    }
                                    onBlur={({ target: { name, value, defaultValue } }) =>
                                        value !== defaultValue &&
                                        mutate([
                                            _id,
                                            { [name]: value ? new Date(value).toJSON() : '' },
                                        ])
                                    }
                                />
                            </label>

                            <label>
                                Motivo de baja:
                                <input
                                    name="quitReason"
                                    list="quitReasonList"
                                    type="text"
                                    defaultValue={quitReason}
                                    onBlur={({ target: { name, value } }) =>
                                        quitReason !== value && mutate([_id, { [name]: value }])
                                    }
                                />
                                <datalist id="quitReasonList">
                                    {quitReasons.map((reason) => (
                                        <option key={reason}>{reason}</option>
                                    ))}
                                </datalist>
                            </label>
                        </div>
                        {content && (
                            <label>
                                Día y contenido académico:
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '100px auto',
                                        gap: '1em',
                                    }}
                                >
                                    <input
                                        type="number"
                                        name="contentDay"
                                        id="contentDay"
                                        min={1}
                                        defaultValue={day}
                                        onBlur={({
                                            target: { value, valueAsNumber, defaultValue },
                                        }) =>
                                            value !== defaultValue &&
                                            valueAsNumber > 0 &&
                                            mutateContent([
                                                {
                                                    profileId: _id,
                                                    newContentDay: valueAsNumber,
                                                },
                                            ])
                                        }
                                    />
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={
                                            day === 0
                                                ? capitalize(`${message}`)
                                                : capitalize(`${description}`)
                                        }
                                        disabled={true}
                                    />
                                </div>
                            </label>
                        )}
                    </>
                )}
                <div className="columns">
                    <label>
                        Institución:
                        <input
                            name="institution"
                            type="text"
                            defaultValue={institution}
                            onBlur={({ target: { name, value } }) =>
                                institution !== value && mutate([_id, { [name]: value }])
                            }
                        />
                    </label>

                    <label>
                        Adquisición:
                        <input
                            name="acquisitionType"
                            type="text"
                            defaultValue={acquisitionType}
                            onBlur={({ target: { name, value } }) =>
                                acquisitionType !== value && mutate([_id, { [name]: value }])
                            }
                        />
                    </label>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <img
                    src={urlImage || '/no-profile-image.png'}
                    alt={displayName}
                    title={displayName}
                    style={{ maxWidth: '-webkit-fill-available' }}
                    onClick={updateProfileImage}
                    aria-hidden="true"
                />
                <br />
                <button
                    className="outlined"
                    onClick={() => (location.href = `/users/manage/${_userId._id}`)}
                >
                    Datos del Usuario
                </button>
            </div>
        </section>
    )
}
