const MAX_SIZE = 15

export const useRecent = () => {
    const saveHistory = (search: HistoryItemInterface) => {
        const searchHistory = getHistory()
        const alreadyIn = !!searchHistory.filter((item) => item.title === search.title).length
        if (alreadyIn) {
            const index = searchHistory.map((item) => item.title).indexOf(search.title)
            searchHistory.splice(index, 1)
        }
        searchHistory.splice(MAX_SIZE - 1, searchHistory.length)
        localStorage.setItem('searchHistory', JSON.stringify([search, ...searchHistory]))
    }

    const getHistory = (props?: HistoryInterface) => {
        const searchHistory = localStorage.getItem('searchHistory')
        if (!searchHistory) return []
        const history = JSON.parse(searchHistory) as HistoryItemInterface[]
        return history.filter((item) => item.title.includes(props?.filter || ''))
    }

    const deleteHistory = (url: HistoryItemInterface['url']) => {
        const searchHistory = getHistory()
        const newSearchHistory = searchHistory.filter((item) => item.url !== url)
        localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory))
    }

    return {
        saveHistory,
        getHistory,
        deleteHistory,
    }
}

// Interfaces
export interface HistoryInterface {
    title?: string
    filter?: string
}

export interface HistoryItemInterface {
    title: string
    url: string
    hqName?: string
}
