import { Column, TableOptions, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import React, { useEffect, useState } from 'react'
import Styles from './Table.module.css'
import { downloadCSV } from '../Helpers/files'
import { Icon } from './Icon'

//Styles

const DEFAULT_PAGE_SIZE = 50

export const Table = <T extends {}>({
    data,
    columns,
    csvFileName,
    canDownloadCsv = true,
    pageSize = DEFAULT_PAGE_SIZE,
    updateMyData,
    ...rest
}: TableProps<T>) => {
    const [showFilters, setShowFilters] = useState(false)

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        headerGroups,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        columns: allColumns,
    } = useTable<T>(
        {
            data,
            columns,
            updateMyData,
            ...rest,
        },
        useFilters,
        useSortBy,
        usePagination,
    )

    useEffect(() => setPageSize(pageSize || DEFAULT_PAGE_SIZE), [data, setPageSize])

    if (!data.length) return null

    const isFiltrable = !!allColumns.filter((col) => !!col.Filter).length

    const csvHeaders = canDownloadCsv && getHeaders(columns)
    const csvData = rows.map(({ values }) =>
        Object.values(values)
            .map((value) =>
                typeof value === 'string' || typeof value === 'number'
                    ? value
                    : value?.props?.children ?? '-',
            )
            .join(';'),
    )

    const dataForCSV = [csvHeaders].concat(csvData)
    const cvsFileName = csvFileName ? `${csvFileName.replaceAll(' ', '_')}.csv` : 'table_data.csv'

    return (
        <>
            <div>
                {canDownloadCsv && (
                    <button
                        className={`outlined ${Styles['downloadCSV']}`}
                        onClick={() => downloadCSV(dataForCSV, cvsFileName)}
                        title="Descargar CSV"
                    >
                        <Icon type="download" />
                    </button>
                )}
                {isFiltrable && (
                    <button
                        className={`outlined ${Styles['downloadCSV']}`}
                        onClick={() => setShowFilters((prev) => !prev)}
                        title="Filtra"
                    >
                        <Icon type="filter" />
                    </button>
                )}
                {
                    // Show filter if some column has filter property
                    isFiltrable && showFilters && (
                        <div className={Styles.FilterContainer}>
                            {headerGroups.map((headerGroup) =>
                                headerGroup.headers.map(
                                    (column) =>
                                        column.Filter && (
                                            <div key={column.id}>
                                                {column.render('Filter', { data })}
                                            </div>
                                        ),
                                ),
                            )}
                        </div>
                    )
                }
            </div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={headerGroup.getHeaderGroupProps().key}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    key={column.getHeaderProps().key}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted && (column.isSortedDesc ? ' ↑ ' : ' ↓ ')}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {(data.length > (pageSize ?? DEFAULT_PAGE_SIZE) ? page : rows).map((row) => {
                        prepareRow(row)
                        const { key, ...restRowProps } = row.getRowProps()
                        return (
                            <tr key={key} {...restRowProps}>
                                {row.cells.map((cell) => {
                                    const { key, ...restCellProps } = cell.getCellProps()
                                    return (
                                        <td key={key} {...restCellProps}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {data.length > (pageSize || DEFAULT_PAGE_SIZE) && (
                <div className={Styles['pagination']}>
                    <button
                        className="outlined"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </button>
                    <button
                        className="outlined"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </button>
                    <span>
                        {rest.pageIndex ?? 0 + 1} / {pageOptions.length}
                    </span>
                    <button className="outlined" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                    <button
                        className="outlined"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </button>
                </div>
            )}
        </>
    )
}

export const Filter = <T extends {}>({ column, data, helper = true }: FilterProps<T> | any) => {
    const { Header, filter: defaultFilter, filterValue, setFilter, id } = column
    const [help] = useState(helper)

    useEffect(() => {
        setFilter(defaultFilter)
    }, [defaultFilter])

    const handleList = () => {
        //@ts-expect-error type error
        const arrayOfValues = data.map((item) => item[id])
        const option = [...new Set(arrayOfValues)].map((item) => {
            if (typeof item === 'object' || !item) return

            if (typeof item === 'boolean')
                return <option key={`${item}`}>{item && 'Activo'}</option>
            //@ts-expect-error type error
            return <option key={`${item}`}>{item}</option>
        })
        return option
    }

    return (
        <div>
            <input
                type="search"
                className={Styles.filterInput}
                placeholder={Header}
                defaultValue={filterValue}
                onChange={({ target: { value } }) => {
                    setFilter(`${value}`)
                }}
                onClick={(e) => e.stopPropagation()}
                list={`list-${String(id)}`}
            />
            {help && <datalist id={`list-${String(id)}`}>{handleList()}</datalist>}
        </div>
    )
}

function updateOneRow<T>(data: T[], rowIndex: number, columnId: string, value: unknown) {
    return data.map((row, index) => {
        if (index === rowIndex) {
            return {
                ...data[rowIndex],
                [columnId]: value,
            }
        }
        return row
    })
}

function update<T>(
    setter: React.Dispatch<React.SetStateAction<T[]>>,
    validator: Function | undefined,
    rowIndex: number,
    columnId: string,
    value: unknown,
) {
    setter((data) =>
        validator
            ? validator(updateOneRow(data, rowIndex, columnId, value))
            : updateOneRow(data, rowIndex, columnId, value),
    )
}

export const EditableCell = ({
    value,
    row,
    column,
    updateMyData,
    setter,
    validator,
}: EditableProps) => {
    const [newValue, setNewValue] = useState(value)
    const { index } = row
    const { id } = column

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setNewValue(e.target.value)

    const onBlur = () =>
        updateMyData
            ? updateMyData(index, id, newValue)
            : setter && update(setter, validator, index, id, newValue)

    useEffect(() => setNewValue(value), [value])

    return (
        <input
            style={{ border: '1px solid grey', padding: '7px' }}
            value={newValue}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}

const getHeaders = <T extends Record<string, unknown>>(
    columns: Column<T>[],
    separator = ': ',
    parent?: Column['Header'],
): string =>
    columns
        .map((column) =>
            !('columns' in column)
                ? `${parent ? parent + separator : ''}${column.Header}`
                : getHeaders(column.columns, separator, `${column.Header}`),
        )
        .join(';')

// Interface
interface TableProps<T extends Record<string, unknown>>
    extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>,
        TableOptions<T> {
    data: T[]
    columns: Column<T>[]
    withPagination?: boolean
    csvFileName?: string
    canDownloadCsv?: boolean
    pageSize?: number
    // eslint-disable-next-line no-unused-vars
    updateMyData?: EditableProps['updateMyData']
    setter?: EditableProps['setter']
    validator?: EditableProps['validator']
}

interface FilterProps<T extends Record<string, unknown>> {
    data: T[]
    helper?: boolean
    column: {
        filter: string
        Header: string
        id: keyof T
        filterValue: string
        setFilter: React.Dispatch<React.SetStateAction<string | boolean>>
    }
}

interface EditableProps {
    value: string
    row: {
        index: number
    }
    column: {
        id: string
    }
    // eslint-disable-next-line no-unused-vars
    updateMyData?: (index: number, id: string, value: any) => void
    setter?: React.Dispatch<React.SetStateAction<any>>
    // eslint-disable-next-line no-unused-vars
    validator?: (items: any) => void
}
