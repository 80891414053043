import React from 'react'
import { DescendantRoutes, RoutesType } from '../../Router/DescendantRoutes'
import { PermissionDenied } from '../PermissionDenied/PermissionDenied'
import { ProductManage } from './Manage/ProductManage'
import { ProductsSearchManage } from './Manage/ProductsSearchManage'

export const ProductsRoutesV1 = () => {
    const routes: RoutesType = [
        {
            path: '',
            role: 'admin',
            element: <ProductsSearchManage />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: ':id',
            role: 'admin',
            element: <ProductManage />,
            exitRoute: <PermissionDenied />,
        },
    ]
    return <DescendantRoutes propsElements={routes} />
}
