import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Title } from '../../../Components/Title'
import { useOrganization } from '../Hooks/useOrganizations'
import { AddEditOrganizations } from './AddEditOrganizations'
import { useRecent } from '../../../Hooks/useRecent'
import { OrganizationCoursesList } from './OrganizationCoursesList'
import { AddEditCourse } from './AddEditCourse'
import { EcosystemTitle } from './EcosystemTitle'
import { OrganizationProfiles } from './OrganizationProfiles'

export const Organization = () => {
    const { slug } = useParams()
    const { saveHistory } = useRecent()
    const [isOpen, setIsOpen] = useState(false)
    const [createCourse, setCreateCourse] = useState(false)

    const isOrphan = slug === 'none'
    const { organization, isLoading } = useOrganization(!isOrphan ? slug : undefined)

    const title = isOrphan
        ? 'Cursos sin organización'
        : !organization?.logo
        ? organization?.name
        : null

    organization &&
        saveHistory({ title: `Organization ${organization.name}`, url: location.pathname })

    if (isLoading) return <p>Cargando...</p>
    if (!organization && !isOrphan) return <p>Organización no encontrada</p>

    return (
        <>
            <EcosystemTitle />

            <section>
                <Title
                    title={title}
                    image={organization?.logo}
                    subtitle={
                        isOrphan
                            ? undefined
                            : [
                                  `Organization ID: ${organization?.id}`,
                                  `Slug: ${organization?.slug}`,
                              ]
                    }
                >
                    {!isOrphan && (
                        <button className="outlined" onClick={() => setIsOpen(true)}>
                            EDITAR
                        </button>
                    )}
                    <button className="primary" onClick={() => setCreateCourse(true)}>
                        AGREGAR CURSO
                    </button>
                </Title>

                <OrganizationCoursesList slug={`${slug}`} />

                <OrganizationProfiles />
            </section>
            {!isOrphan && (
                <AddEditOrganizations
                    id={organization?.slug ?? organization?.id}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    btnConfirmLabel="Guardar"
                />
            )}
            <AddEditCourse
                isOpen={createCourse}
                onClose={() => setCreateCourse(false)}
                course={{
                    organizationId: organization?.id ?? null,
                    title: 'Nuevo Curso',
                    slug: 'nuevo-curso',
                    contentType: '',
                    lang: 'es',
                    enabled: true,
                    visible: true,
                    public: false,
                }}
            />
        </>
    )
}
