import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Column } from 'react-table'
import Styles from '../../Team/Bulk/styles.module.css'
import { Table } from '../../../Components/Table'
import { Title } from '../../../Components/Title'
import { downloadFile } from '../../../Helpers/files'
import { isValidEmail } from '../../../Helpers/validators'
import { useCohort } from '../Hooks/useCohorts'
import { useUIContext } from '../../../Context/UIContext'
import { useXlsxReader } from '../../../Hooks/useXlsxReader'

export const CohortBulk = () => {
    const { id = '' } = useParams()
    const { showError } = useUIContext()
    const { bulk, bulkLoading } = useCohort(id)
    const [users, setUsers] = useState<User[]>([])
    const { handleUpload } = useXlsxReader<User>((results) =>
        results.length > 250
            ? showError('Máximo 250 usuarios')
            : setUsers((old) => old.concat(validate(results))),
    )

    const haveErrors = useMemo(() => users.filter(({ error }) => !!error), [users])

    const onBulk = () =>
        bulk(
            { id, users },
            {
                onSuccess: ({ errors }) => {
                    errors.length && setUsers(errors)
                },
            },
        )

    const addUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { email, institution, acquisitionType } = e.target as unknown as FormData

        if (!email.value.trim()) return showError('Ingrese un email válido')

        if (users.some((user) => user.email === email.value))
            return showError('El usuario ya se encuentra en la lista')

        setUsers((old) => [
            {
                email: email.value,
                institution: institution.value,
                acquisitionType: acquisitionType.value,
            },
            ...old,
        ])

        //@ts-expect-error ...
        e.target.reset()
    }

    const acquisitionList = useMemo(
        () => [...new Set(users.map(({ acquisitionType }) => acquisitionType ?? ''))],
        [users],
    )

    const institutionList = useMemo(
        () => [...new Set(users.map(({ institution }) => institution ?? ''))],
        [users],
    )

    return (
        <section>
            <main className="column-2">
                <form onSubmit={addUser}>
                    <label htmlFor="email">
                        Email:
                        <input type="email" name="email" id="email" />
                    </label>
                    <div className="column-2">
                        <label htmlFor="institution">
                            Institución:
                            <input
                                type="text"
                                name="institution"
                                id="institution"
                                list="institutions-list"
                            />
                            <datalist id="institutions-list">
                                {institutionList.map((item) => (
                                    <option key={item} value={item} />
                                ))}
                            </datalist>
                        </label>
                        <label htmlFor="acquisitionType">
                            Tipo de adquisición:
                            <input
                                type="text"
                                name="acquisitionType"
                                id="acquisitionType"
                                list="acquisition-list"
                            />
                            <datalist id="acquisition-list">
                                {acquisitionList.map((item) => (
                                    <option key={item} value={item} />
                                ))}
                            </datalist>
                        </label>
                    </div>
                    <br />
                    <div className="column-2">
                        <button className="success">Agregar usuario</button>
                        <label
                            style={{
                                margin: 0,
                                textAlign: 'center',
                                backgroundColor: 'var(--color-action)',
                                color: 'white',
                                display: 'grid',
                                placeItems: 'center',
                                fontSize: 13,
                                cursor: 'pointer',
                            }}
                        >
                            <div>Cargar CSV/Excel</div>
                            <input
                                hidden
                                className={`${Styles['custom-file-input']}`}
                                type="file"
                                accept=".xls, .xlsx, .csv"
                                onChange={handleUpload}
                                key={users.length}
                            />
                        </label>
                    </div>
                </form>
                <div style={{ textAlign: 'center' }}>
                    <p>
                        <b>
                            Solo se permitirá el alta de usuarios existentes y que no tengan un
                            perfil activo en este mismo curso.
                        </b>
                    </p>
                    <p>
                        Si no tiene el template necesario para el alta masiva, puede{' '}
                        <button
                            className="outlined"
                            onClick={() => downloadFile('cohort_bulk.xlsx', 'cohort_bulk.xlsx')}
                        >
                            descárguelo de aquí.
                        </button>
                    </p>
                    {!!users.length && (
                        <>
                            <button
                                className="danger"
                                onClick={onBulk}
                                disabled={!!haveErrors.length || bulkLoading}
                            >
                                Agregar {users.length} usuarios
                            </button>
                            <br />
                            <br />
                            <button
                                onClick={() =>
                                    confirm(
                                        '¿Está seguro que desea borrar la lista de usuarios y comenzar nuevamente?',
                                    ) && setUsers([])
                                }
                                className="outlined"
                            >
                                Cancelar
                            </button>
                        </>
                    )}
                </div>
            </main>
            <div style={{ margin: '1em 0' }}>
                {!!haveErrors.length && <Title title="Usuarios no enrolados" />}
                <Table setter={setUsers} validator={validate} data={haveErrors} columns={columns} />
            </div>
        </section>
    )
}

const validate = (users: User[]): User[] =>
    users
        .map((item) => ({ ...item, error: !isValidEmail(item.email) ? 'Email inválido' : '' }))
        .sort((a, b) => b.error?.localeCompare(a.error ?? '') ?? 0)

const columns: Column<User>[] = [
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Institution',
        accessor: 'institution',
    },
    {
        Header: 'AcquisitionType',
        accessor: 'acquisitionType',
    },
    {
        Header: 'Error',
        accessor: 'error',
    },
]

interface User {
    email: string
    institution?: string
    acquisitionType?: string
    error?: string
}

interface FormData {
    email: HTMLInputElement
    institution: HTMLInputElement
    acquisitionType: HTMLInputElement
}
