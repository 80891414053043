import { useMemo } from 'react'
import { UserProfileIcon } from '../Pages/Users/Components/ProfileManage'
import { Section } from './Section'
import { Filter, Table } from './Table'
import { CourseProfilesDTO, CourseStatus } from '../infrastructure/api-activation'
import { Column } from 'react-table'
import { useNavigate } from 'react-router-dom'

export const CourseProfilesList = ({
    data,
    status,
    title = 'Usuarios',
}: {
    data?: CourseProfilesDTO[]
    status: 'idle' | 'error' | 'loading' | 'success'
    title?: string
}) => {
    const navigate = useNavigate()
    const columns = useMemo(
        () =>
            [
                {
                    id: 'email',
                    Header: 'Email',
                    accessor: 'email',
                    Filter,
                },
                {
                    id: 'userID',
                    Header: 'User ID',
                    accessor: ({ userId }) => <small>{userId}</small>,
                },
                {
                    id: 'courseName',
                    Header: 'Curso',
                    accessor: ({ courseTitle, courseSlug, courseId }) => (
                        <button
                            className="outlined"
                            onClick={() => navigate(`/ecosystem/courses/${courseSlug ?? courseId}`)}
                        >
                            <small>{courseTitle ?? '-'}</small>
                        </button>
                    ),
                },
                {
                    id: 'profileId',
                    Header: 'Profile ID',
                    accessor: ({ profileId }) => <small>{profileId}</small>,
                },
                {
                    id: 'role',
                    Header: 'Rol',
                    accessor: 'role',
                    Filter,
                },
                {
                    id: 'status',
                    Header: 'Estado',
                    accessor: ({ status }) => (
                        <span title={statusOfUsers[status].title}>
                            {statusOfUsers[status].status}
                        </span>
                    ),
                },

                {
                    id: 'options',
                    Header: '',
                    accessor: ({ profileId }) => <UserProfileIcon _profileId={profileId} />,
                },
            ] as Column<CourseProfilesDTO>[],
        [],
    )

    return (
        <Section title={title} subtitle={[`_Cantidad: ${data?.length ?? '-'}`]}>
            {status === 'error' ? (
                <p className="danger">No se pudieron cargar los usuarios del curso</p>
            ) : status === 'loading' ? (
                <p>Cargando los usuarios del curso...</p>
            ) : status === 'success' && !data?.length ? (
                <p className="info">No hay usuarios en este curso</p>
            ) : status === 'success' && data?.length ? (
                <Table columns={columns} data={data} pageSize={15} Title="Usuarios" />
            ) : null}
        </Section>
    )
}

const statusOfUsers: Record<CourseStatus, { status: string; title: string }> = {
    unknown: {
        status: 'Desconocido',
        title: 'No se posee información suficiente para determinar el estado del usuario',
    },
    enrolled: {
        status: 'Inscripto',
        title: 'El usuario está inscripto en el curso pero aún no comenzó',
    },
    studying: {
        status: 'Estudiando',
        title: 'El usuario está cursando',
    },
    finished: {
        status: 'Finalizado',
        title: 'El usuario finalizó el curso',
    },
    deserted: {
        status: 'Dado de Baja',
        title: 'El usuario fué dado de baja del curso',
    },
}
