import { OrganizationList } from './Components/OrganizationList'
import { EcosystemTitle } from './Components/EcosystemTitle'

export const EcosystemHome = () => {
    return (
        <div>
            <EcosystemTitle />

            <OrganizationList />
        </div>
    )
}
