import { useState } from 'react'
import { quitReasons } from '../../../../AppConfig'
import { ProfileDataWithMutation, ProfileMutationContent } from '../ProfileManage'
import { Alert } from '../../../../Components/Alert'
import DayJS from '../../../../Helpers/DayJS'

export const ProfileActions = (data: ProfileMutationContent) =>
    data.active ? <EditProfile {...data} /> : <ReactivateProfile {...data} />

const EditProfile = (props: ProfileDataWithMutation) => {
    const { algorithmType } = props._classId ?? {}
    const isHCA4 = algorithmType === 'hca4'

    return isHCA4 ? (
        <div className="columns">
            <fieldset style={{ padding: '2em' }}>
                <legend>Estado del perfil</legend>
                <DisabledProfile {...props} isHCA4={isHCA4} />
            </fieldset>
            <fieldset style={{ padding: '2em' }}>
                <legend>Usuario con acceso a todo el contenido</legend>
                <ExpertMentor {...props} />
            </fieldset>
        </div>
    ) : null
}

const ReactivateProfile = ({
    _id,
    _classId,
    quitReason,
    setToUnactiveDate,
    content,
    role,
    progressiveSchedule,
    mutate,
    mutateContent,
}: ProfileMutationContent) => {
    const [start, setStart] = useState(DayJS().add(1, 'day').hour(0).minute(0).toJSON())
    const { algorithmType, courseDuration } = _classId ?? {}
    const { startDateCourse, endDateCourse } = courseDuration ?? {}
    const isHCA4 = algorithmType === 'hca4'
    const onlyHcaText = !isHCA4 ? '*HCA4' : ''

    const isValidStartDay = DayJS(start).isAfter(DayJS())

    const onSave = () => {
        mutate([_id, { active: true, start }])
        mutateContent([{ profileId: _id, newContentDay: 1 }])
    }

    const { day } = content ?? {}

    const minStart = progressiveSchedule?.start
        ? DayJS(progressiveSchedule.start).format('YYYY-MM-DDTHH:mm')
        : startDateCourse
        ? DayJS(startDateCourse).format('YYYY-MM-DDTHH:mm')
        : undefined

    const maxStart = endDateCourse ? DayJS(endDateCourse).format('YYYY-MM-DDTHH:mm') : undefined

    return (
        <>
            <p className="danger">
                ATENCIÓN: Este perfil fue desactivado {DayJS(setToUnactiveDate).fromNow()}, el{' '}
                {DayJS(setToUnactiveDate).format('LLL')}, porque
                <i> {` "${quitReason}"`.toLowerCase()}</i>.
            </p>
            {isHCA4 && role === 'student' && (
                <>
                    <label>
                        Fecha de inicio de cursado: {onlyHcaText}
                        <input
                            style={{ width: '40ch' }}
                            name="start"
                            type="datetime-local"
                            min={minStart}
                            max={maxStart}
                            value={DayJS(start).format('YYYY-MM-DDTHH:mm')}
                            onChange={({ target: { value } }) =>
                                value ? setStart(new Date(value).toJSON()) : ''
                            }
                            disabled={!isHCA4}
                        />
                    </label>

                    {day !== 1 && (
                        <p className="danger">El contenido académico será restaurado al día 1.</p>
                    )}
                </>
            )}
            <br />
            <button className="success" disabled={isHCA4 && !isValidStartDay} onClick={onSave}>
                Reactivar
            </button>
        </>
    )
}

const DisabledProfile = ({
    _id,
    mutate,
    isHCA4,
}: ProfileDataWithMutation & { isHCA4: boolean }) => {
    const [quitReason, setQuitReason] = useState('')

    const onSave = () => {
        mutate([
            _id,
            {
                active: false,
                quitReason: quitReason,
            },
        ])
    }

    return (
        <>
            {isHCA4 && (
                <label>
                    Motivo de Baja:
                    <input
                        name="quitReason"
                        list="quitReasonList"
                        type="text"
                        value={quitReason}
                        onChange={({ target: { value } }) => setQuitReason(value)}
                        disabled={!isHCA4}
                    />
                    <datalist id="quitReasonList">
                        {quitReasons.map((reason) => (
                            <option key={reason}>{reason}</option>
                        ))}
                    </datalist>
                </label>
            )}
            <button className="danger" disabled={isHCA4 && !quitReason} onClick={onSave}>
                Pausar
            </button>
        </>
    )
}

const ExpertMentor = ({
    _id,
    displayName,
    isExpertMentor,
    mutate,
    role,
}: ProfileDataWithMutation) => {
    const [isOpen, setIsOpen] = useState(false)
    if (role !== 'student') return null

    if (isOpen)
        return (
            <Alert
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={
                    <div style={{ textAlign: 'center' }}>
                        <h2>Convertir en Usuario con acceso a todo el contenido</h2>
                        <p style={{ maxWidth: 300 }}>
                            ¿ Está seguro que desea convertir a <b>{displayName}</b> en un usuario
                            con acceso a todo el contenido y avanzar su contenido al último día?
                        </p>
                        <p className="danger">
                            <b>¡Esta acción no puede ser revertida!</b>
                        </p>
                    </div>
                }
                onConfirm={() => mutate([_id, { isExpertMentor: true }])}
            />
        )

    return !isExpertMentor ? (
        <section style={{ display: 'grid' }}>
            <p>
                <small>
                    Al darle al usuario <b>acceso a todo el contenido</b>, se avanzará el contenido
                    del mismo al último día.{' '}
                </small>
                <p className="danger">
                    <small>
                        <b>¡Esta acción no puede ser revertida!</b>
                    </small>
                </p>
            </p>
            <button className="primary" onClick={() => setIsOpen(true)}>
                CONVERTIR
            </button>
        </section>
    ) : (
        <section>
            <p className="info">El usuario tiene acceso a todo el contenido.</p>
        </section>
    )
}
