import { bulkCohort, getCohort } from '../api'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useUIContext } from '../../../Context/UIContext'
import { getNextCohortsByCourseSlug } from '../../../infrastructure/api-activation'

export const useCohort = (id: string) => {
    const { showToast, showError } = useUIContext()
    const queryClient = useQueryClient()

    const queryKey = ['Cohort', id]
    const queryFn = () => getCohort(id)

    const { data: cohort, ...rest } = useQuery({ queryKey, queryFn, staleTime: 1000 * 60 * 5 })

    const { mutate: bulk, isLoading: bulkLoading } = useMutation({
        mutationFn: bulkCohort,
        onMutate: () =>
            showToast('Carga masiva iniciada, por favor espere un momento...', 'info', 7000),
        onSuccess: () => showToast('Carga masiva completada'),
        onError: ({ response }) => showError(response.data.message),
        onSettled: () => queryClient.invalidateQueries(queryKey),
    })

    return { cohort, bulk, bulkLoading, ...rest }
}

export const useCohortsNext = ({ slug }: { slug?: string }) => {
    const queryKey = ['Course', 'Get', slug, 'Cohorts']
    const queryFn = async () => getNextCohortsByCourseSlug(slug as string)

    const { data: cohorts, ...rest } = useQuery({ queryKey, queryFn, enabled: !!slug })

    return { cohorts, ...rest }
}
