export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '2em 3em',
        maxWidth: '800px',
        minWidth: '400px',
        margin: '0 auto',
    },
}
